import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Container } from 'reactstrap';
import {
  AppAside,
  AppBreadcrumb,
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppSidebarNav,
} from '@coreui/react';
// sidebar nav config
import fullnavigation from '../../_nav';
// routes config
import routes from '../../routes';
import DefaultAside from './DefaultAside';
import DefaultFooter from './DefaultFooter';
import DefaultHeader from './DefaultHeader';
import {Fetch} from '../../services/Fetch';



class DefaultLayout extends Component {

  constructor(props) {
    super(props);
   
    this.state = {
      redirectToReferrer: false,  
      navigation:fullnavigation,
    };
    this.checkToken = this.checkToken.bind(this);
  } 

 async checkToken(_id,_token){
 	var _json = {"token":_token}
 	var  _res = await Fetch('post','users/checkId',_json);
 	if (_res.results===_id){
      this.getSubscriptionInfo(_id)
 	} else {
 		localStorage.removeItem("userData");
 		this.setState({redirectToReferrer: true});
 	}

 }

  async getSubscriptionInfo(_id) {
    var _json = {}

    var _res = await Fetch('get', `subscription/getSubscriptionInfo/${_id}`)
    if (_res.results.hasOwnProperty("player_id")) {
      localStorage.setItem('subscription', JSON.stringify(_res.results))
    }

  }

  componentDidMount() {        
       var _jsonurl = {
       					page:this.props.location.pathname,
       					query:this.props.location.search
       				  } 
       	if ((this.props.location.pathname.indexOf("profile") >=0 ) || (this.props.location.pathname.indexOf("iframe") >=0 )) {

       	} else {
       		sessionStorage.setItem('UrlData',JSON.stringify(_jsonurl));
       	}		  
           
	     if(localStorage.getItem("userData")){
	     var datalogin = JSON.parse(localStorage.getItem("userData"));
		         if (datalogin.hasOwnProperty("id")){
		         	this.checkToken(datalogin.id,datalogin.token);
		         }else{
		         	localStorage.removeItem("userData")
		         	this.setState({redirectToReferrer: true});
		         }
	     }    
	     else{
	     	this.setState({redirectToReferrer: true});
	     }       
   }
 

  render() {
    if (this.state.redirectToReferrer) {
      return (<Redirect to={'/login'}/>)
    }

    var LabelBackgroud = ({url}) => {
    	if (url.indexOf("iframe") >= 0 ){
    		return ("");
    	} else {
    		return (<div className="bg-cards"></div>);
    	}
    }	
	  return (
	  <React.Fragment>
	  <div className="wrapper">
            <LabelBackgroud url={this.props.location.pathname} />
			<div className="inner">
				<AppHeader>
				  <DefaultHeader navConfig={this.state.navigation} {...this.props} />
			   </AppHeader>
				<Container fluid>
				  <Switch>
					{routes.map((route, idx) => {
						return route.component ? (<Route key={idx} path={route.path} exact={route.exact} name={route.name} render={props => (
							<route.component {...props} data={this.state}/>
						  )} />)
						  : (null);
					  },
					)}
					<Redirect from="/private" to="/login" />
				  </Switch>
				</Container>
				
				
				<AppAside fixed>
					<DefaultAside />
				  </AppAside>
				<AppFooter>
				  <DefaultFooter />
				</AppFooter>
			</div>
		</div>
		</React.Fragment>
    );

  }
}

export default DefaultLayout;
