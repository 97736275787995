export const appendScriptHead = (appendScriptHead,type) => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = appendScriptHead;
    if (type){
    	script.async = true;
    } else {
    	script.async = false
    }
    document.head.appendChild(script);
}