import React, { Component } from 'react';
import config from '../../../config';
import { withTranslation } from 'react-i18next';


class Footer extends Component {

  constructor(props){
    super(props);   
    this.state = {
      isHome:this.props.home,
      isRegister:this.props.register,

    };

  }



  render() {
    const { t } = this.props;
    var {isHome,isRegister} = this.state;

    return (
      <footer className={`${isHome ? "footer-home" : ""}`}>Poker peek software, s.l.
       {t("All rights reserved")} &copy;2021 
       <a href={config.WEB_URL + 'legal/legal.html'} target={`${isRegister ? "_blank" : "_self"}`}>{t("Legal")}</a> | 
       <a href={config.WEB_URL + 'legal/cookies.html'} target={`${isRegister ? "_blank" : "_self"}`}>{t("Cookies")}</a> | 
       <a href={config.WEB_URL + 'legal/privacy_policy.html'} target={`${isRegister ? "_blank" : "_self"}`}>{t("Privacy")}</a>
      </footer>        
    );
  }
}


export default withTranslation()(Footer);
