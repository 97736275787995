import React from 'react';
import Loadable from 'react-loadable'

import DefaultLayout from './containers/DefaultLayout';

function Loading() {
  return <div>Loading...</div>;
}



const Dashboard = Loadable({
  loader: () => import('./views/Dashboard'),
  loading: Loading,
});




const Clubs = Loadable({
  loader: () => import('./views/Poker/Clubs'),
  loading: Loading,
});


const Club = Loadable({
  loader: () => import('./views/Poker/Club'),
  loading: Loading,
});


const Iframe = Loadable({
  loader: () => import('./views/Poker/Iframe'),
  loading: Loading,
});

const BroadCast = Loadable({
  loader: () => import('./views/Poker/Iframe/BroadCast'),
  loading: Loading,
});


const Games = Loadable({
  loader: () => import('./views/Poker/Games'),
  loading: Loading,
});

const Player = Loadable({
  loader: () => import('./views/Poker/Player'),
  loading: Loading,
});

const Profile = Loadable({
  loader: () => import('./views/Poker/Profile'),
  loading: Loading,
});


const TournamentRoute = Loadable({
  loader: () => import('./views/Poker/Tournament/TournamentRoute'),
  loading: Loading,
});



// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  /*
  { path: '/', exact: true, name: 'Home', component: DefaultLayout },
  { path: '/dashboard', name: 'Dashboard', component: Dashboard },
  { path: '/theme', exact: true, name: 'Theme', component: Colors },
  { path: '/theme/colors', name: 'Colors', component: Colors },
*/
  { path: '/private/games', exact: true, name: 'Games', component: Games },
  { path: '/private/clubs', exact: true, name: 'Clubs', component: Clubs },
  { path: '/private/clubs/:club_token/', exact: true, name: 'Club', component: Club },
  { path: '/private/clubs/:club_token/:tournament_token/', exact: true, name: 'Tournament', component: TournamentRoute },
  { path: '/private/player', exact: true, name: 'Player', component: Player },
  { path: '/private/profile', exact: true, name: 'Profile', component: Profile },
  { path: '/private/iframe/actions/:club_token/:tournament_token/', exact: true, name: 'Iframe', component: Iframe },
  { path: '/private//iframe/broadcast/:club_token/:tournament_token/', exact: true, name: 'BroadCast', component: BroadCast },


];

export default routes;
