import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import {PostData} from '../../../services/PostData';
import {Fetch} from '../../../services/Fetch';
import { Container } from 'reactstrap';

import configuration from '../../../config';
import {appendScriptHead} from './Components/appendScriptHead';
import {appendScriptBody} from './Components/appendScriptBody';
import Footer from '../Footer/Footer';
import { withTranslation } from 'react-i18next';



class RegisterStep2 extends Component {

  constructor(props){
    super(props);
   
    this.state = {
        token:this.props.match.params.token || '',
        captureimage:'/assets/img/avatars/5.jpg',
        showImage: false,
        showButtonWebcam:false,
        showButtoninitWebcam:true,
        showCapturePicture:false,
        showFinishButton:false,
        showcanvas:true,
        email:'',
        redirectToLogin:false,
        urltoredirect:'',
        terms:true,
        showEmoji:false,
        showBlueMessage:true,
        showConnectingCamera:false,
    };

    this.getMailTmp = this.getMailTmp.bind(this);
    this.getImage = this.getImage.bind(this);
    this.getWebcam = this.getWebcam.bind(this);
    this.finish = this.finish.bind(this);
    this.initWebcam = this.initWebcam.bind(this);
    this.destroyWebCam = this.destroyWebCam.bind(this);
    this.webcamReady = this.webcamReady.bind(this);
    this.onChange = this.onChange.bind(this);
  }
 

  async finish(){

    var _json = { "email":this.state.email,
                  "image":this.state.captureimage

    }
    var  _res = await Fetch('post','users/image' ,_json); 
    if (_res.results){
      this.refs.fileUploader.click();
      //this.setState({redirectToLogin:true});
      
      //this.props.history.push(this.state.urltoredirect);
      this.props.history.push("/registerstep3/"+ this.state.token);
    } else {
        this.setState({showError:true,
                        error : "Error in registering step 2, please contact the administrator",
      })  
    }


  }

  async destroyWebCam() {
    this.refs.fileUploader.click();
  }

 async initWebcam(){
    this.refs.initWebcam.click();
    this.setState({
      showButtoninitWebcam:false,
      showConnectingCamera:true      
    });
 }

  async webcamReady(){
      this.setState({
        showButtoninitWebcam:false,
        showConnectingCamera:false,
        showCapturePicture:true,
        showEmoji:true,
        showBlueMessage:false,      
      });  
  }


  getImage(){


    const canvasSave = document.getElementById('jeeFaceFilterCanvas');
    const _img = canvasSave.toDataURL('image/jpeg',1);
    this.setState({
           showImage:true,
           showButtonWebcam:true,
           showcanvas:false,
           showFinishButton:true,
           captureimage:_img,
    });
    this.destroyWebCam();

  }

  getWebcam() {
    this.refs.initWebcam.click();
    this.setState({
      showcanvas:true,
      showButtonWebcam: false,
      showFinishButton: false,
      showImage:false
    });    
  }


  getMailTmp(token){

            var _dateNow = new Date();

            PostData('getMailByTokenTmp',{"token":token}).then((result) => {
              
             let responseJson = result.results;
             if(responseJson.email)
             {         
              var min = Math.floor(( _dateNow.getTime() - new Date(responseJson.created_at).getTime()) / (1000 * 60 * 60));
              if (min > 10){
                this.props.history.push("/thankyou/The link has expired");
              }  else {
                if (responseJson.url_redirect){
                  if (responseJson.url_redirect.length>0){
                      var _url_redirect = responseJson.url_redirect.split("?")
                      if (_url_redirect.length > 1){
                        var _jsonurl = {
                                  page:_url_redirect[0],
                                  query:"?"+_url_redirect[1]
                                  } 
                        sessionStorage.setItem('UrlData',JSON.stringify(_jsonurl));
                        this.setState({urltoredirect:responseJson.url_redirect});
                      }

                  }
                }                
                this.setState({email:responseJson.email})
              }              

                                   
             } else {
                this.props.history.push("/thankyou/The link has expired");
             }
             
            });    
  }

 onChange(e){
   this.setState({[e.target.name]:e.target.value});
  }

  componentDidMount() {   
    
    this.getMailTmp(this.state.token); 

    appendScriptHead("/js/jeeliz/libs/threejs/three-nebula/three-nebula.js",false);
    appendScriptHead("/js/jeeliz/libs/threejs/117/three.min.js",false);
    appendScriptHead("/js/jeeliz/libs/threejs/117/GLTFLoader.js",false);
    appendScriptHead("/js/jeeliz/libs/threejs/117/RGBELoader.js",false);

    appendScriptHead("/js/jeeliz/dist/JeelizVariable.js",false);
    appendScriptHead("/js/jeeliz/dist/jeelizFaceFilter.js",false);
    



    appendScriptHead("/js/jeeliz/libs/threejs/117/JeelizThreejsHelper.js",false);
  

    
    appendScriptHead("/js/jeeliz/helpers/JeelizResizer.js",false);


    appendScriptHead("/js/jeeliz/libs/threejs/117/VolumetricFire.js",false);
    appendScriptHead("/js/jeeliz/libs/tween/v18_6_0/tween.umd.js",false);
    appendScriptHead("/js/jeeliz/JeelizThreeGlassesCreator.js",false);
    appendScriptHead("/js/jeeliz/jeelizSet.js",false);
    appendScriptHead("/js/jeeliz/particles/freeze/snow.js",false);
    appendScriptHead("/js/jeeliz/particles/winter/snow_winter.js",false);
  




    
    
    appendScriptBody("https://code.jquery.com/jquery-1.8.2.min.js",false);
    appendScriptBody("/js/jeeliz/dist/JeelizInit.js",false);
    //appendScriptBody("https://code.jquery.com/mobile/1.2.0/jquery.mobile-1.2.0.min.js");
      

    //main();


  }

  onChange(event)
  {            
      if (event.target.name==='terms'){
         this.setState({terms:!this.state.terms});
      } else {
         this.setState({[event.target.name]:event.target.value});
      }
      this.setState({showError:false,
                     error:'', 
        })      
  }



  render() {
    const { t } = this.props

   if (this.state.redirectToLogin) {
    return (<Redirect to={'/login'}/>)
  }     

    var getImage = this.getImage.bind(this);
    var getWebcam = this.getWebcam.bind(this);
    var finish = this.finish.bind(this);
    var initWebcam = this.initWebcam.bind(this);
    var webcamReady = this.webcamReady.bind(this);
    var { showError, showEmoji, showBlueMessage, showButtoninitWebcam, showConnectingCamera, showImage } = this.state;

    var ButtonCapturePicture = ({status,tryagain}) => {
      if (status){
          if (tryagain){
            return (<React.Fragment>
                 <div className="text-center" style={{width: 224 +'px'}}>
                <a onClick={() => getWebcam()} className="button gradient-orange cursor-pointer">{t("Try again")}</a>
                </div>
                </React.Fragment>
                );
          } else {
            return (<React.Fragment>
                <div className="text-center" style={{width: 224 +'px'}}>
                    <a onClick={ () => getImage()} className="button gradient-orange cursor-pointer">{t("Take picture")}</a>
                </div>
                </React.Fragment>
                );            
          }

      } else {
        return (<React.Fragment>
                <div className="text-center" style={{width: 224 +'px'}}>
                    <small className="button gradient-gray">{t("Take picture")}</small>
                </div>
            </React.Fragment>
            );        
      }

    }

    var ButtonFinish = ({status}) => {
      if (status){
        return (<React.Fragment>
            <a onClick={ () => finish()} className="button gradient-orange cursor-pointer">{t("Finish")}</a>
            </React.Fragment>
            );
      } else {
        return (<React.Fragment>
            <small className="button gradient-gray">{t("Finish")}</small>
            </React.Fragment>
            );        
      }

    }


    return (
  <div className="wrapper is-guest-user centered-wrap">

      <section className="slogan">
        <div className="logo"></div>
        <div className="title">
          <div className="title-1">
          {t("MEET YOUR FRIENDS")}
          </div>
          <div className="title-2">
            &amp;{t("PLAY PRIVATE POKER LIVE")}
          </div>
        </div>
	 </section>


      <div className="app flex-row align-items-center">
        <Container>
          <div className="row justify-content-center my-4 card-box no-gutters page-register-webcam">
              <div className="col-sm-10 col-lg-8">
              <div className="card-box-header text-center white-text-shadow">{t("CREATE ACCOUNT")}</div>
                  <div className="card-box-body py-4">
                      <div className="title text-center mb-4 h4 font-bold">{t("Take a picture of your face with your webcam")}</div>
                        <div className="row px-4">
                            <div className="col-12 col-lg-auto p-1 mr-4">
                                <div className="capture-webcam">
                                {showConnectingCamera && <small className="force-center w-100 text-center font-medium">{t("Connecting camera ...")}<br />{t("it may take a few seconds")} </small>}
                                <canvas className={`${this.state.showcanvas ? "" : "d-none"}`} width={224} height={224} id='jeeFaceFilterCanvas'></canvas>
                                    <span>
                                            
                                            {showButtoninitWebcam && <small  onClick={ () => initWebcam()} className={`draw animated-border yellow cursor-pointer ${this.state.showButtoninitWebcam ? "" : "d-none"}`}>{t("Start webcam")}</small> }
                                            <input style={{display: 'none'}}  ref="initWebcam" type="text" id="initWebcam"/>
                                            <input style={{display: 'none'}} onClick={ () => webcamReady()} type="text" id="webcamReady"/>
                                            <img id="captureImage" className={`${this.state.showImage ? "" : "d-none"}`} src={this.state.captureimage} />
                                    </span>
                                </div>
                            </div>
                            {showBlueMessage && 
                              <div className="col-12 col-lg p-1 pt-4 pt-lg-1">
                                <small className="text white font-medium">
                                    {t("You need to be able to take a picture with your webcam to create your account. Be sure it is not being used by another application.")}<br /><br />
                                    {t("When you click the start button you will have to give permission to use it in pokerpeek.com")}<br /><br />
                                    {t("You will be able to use AR filters to hide your face or pause the camera during the games.")}<br /><br />
                                    {t("We will use this picture on players' rankings and lists in the pages of clubs you are a member of")}
                                </small>    
                              </div>
                            }                            
                    {showEmoji && !showImage &&
                              <div className="col-12 col-lg p-1 pt-4 pt-lg-1">
                                  <small className="text-muted font-medium">{t("Add a filter to your face if you like!")}</small>
                                  <div className="ai-icons">
                                        <img id="glass" src="/images/emoji/smiling-face-with-sunglasses_1f60e.png" className="ai-icon" />
                                        <img id="aces" src="/images/emoji/poker_emoji_pair_of_aces.png" className="ai-icon" />
                                        <img id="hipnotize" src="/images/emoji/hipnotizado.png" className="ai-icon" />
                                        <img id="flames" src="/images/emoji/poker_emoji_dragon_breath.png" className="ai-icon" />
                                        <img id="hat_pp" src="/images/emoji/face-with-cowboy-hat_1f920.png" className="ai-icon" />
                                        <img id="facedeform" src="/images/emoji/face-screaming-in-fear_1f631.png" className="ai-icon" />
                                        <img id="matrix" src="/images/emoji/poker_emoji_matrix.png" className="ai-icon" />
                                        <img id="mask" src="/images/emoji/poker_emoji_the_mask.png" className="ai-icon" />
                                        <img id="joker" src="/images/emoji/poker_emoji_joker.png" className="ai-icon" />
                                       {/* <img id="winter" src="/images/emoji/poker_emoji_winter.png" className="ai-icon" />*/}
                                        <img id="freeze" src="/images/emoji/freeze.png" className="ai-icon" />
                                       {/* <img id="big_victory_money" src="/images/emoji/Money_with_Wings_Emoji_grande.png" className="ai-icon" /> */}
                                        <img id="face_flame" src="/images/emoji/poker_fire.png" className="ai-icon" />
                                        <img id="payaso" src="/images/emoji/payaso.png" className="ai-icon" />
                                  </div>
                              </div>
                            }
                        </div>
                        {/*
                          <div className="pr-3 mt-2 text-right">
                              <div>
                                  <small>
                                      <input checked={this.state.terms} onChange={this.onChange} type="checkbox" id="terms" name="terms"/>
                                      I accept <a>terms and conditions</a>
                                  </small>
                              </div>
                              {showError && <div><small className="text red">{this.state.error}</small></div> }
                          </div>                          
                          */}


                        <div className="row px-4 mt-2">
                          <div className="col p-2">                               
                              <ButtonCapturePicture status={this.state.showCapturePicture} tryagain={this.state.showButtonWebcam} />
                          </div>
                          <div className="col p-2 text-right">                               
                              {showEmoji && <ButtonFinish status={this.state.showFinishButton} /> }                               
                              <input style={{display: 'none'}}  ref="fileUploader" type="text" id="disableall"/>
                          </div>
                        </div>



                  </div>
              </div>
          </div>        
        </Container>
      </div>
     <Footer home={false} register={true}/> 
    </div>


 
    );
  }
}

export default withTranslation()(RegisterStep2);
