import React, { Component } from 'react';
import PropTypes from 'prop-types';
import config from '../../config';

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

class DefaultFooter extends Component {
  render() {

    // eslint-disable-next-line
    const { children, ...attributes } = this.props;

	return (
      <React.Fragment>
        	Poker peek software, s.l. <span className="white-star"></span>
           All rights reserved &copy;2021 
           <a href={config.WEB_URL + 'legal/legal.html'}>Legal</a> | 
           <a href={config.WEB_URL + 'legal/cookies.html'}>Cookies</a> | 
           <a href={config.WEB_URL + 'legal/privacy_policy.html'}>Privacy</a>
      </React.Fragment>
    );
	
    /*return (
      <React.Fragment>
        <span><a href="https://www.pokerpeek.com">PokerPeek.com</a> &copy; 2019 </span>
        <span className="ml-auto">Powered by <a href="https://www.pokerpeek.com">PokerPeek.com</a></span>
      </React.Fragment>
    );*/
  }
}

DefaultFooter.propTypes = propTypes;
DefaultFooter.defaultProps = defaultProps;

export default DefaultFooter;
