import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import { Container, Input } from 'reactstrap';
import {PostData} from '../../../services/PostData';
import { withTranslation } from 'react-i18next';

class Logout extends Component {

  constructor(){
    super();
   
    this.state = {
     errorform:'',
     error_form:[],
     error_message:[],
     redirectToReferrer:false,

    };

    this.logout = this.logout.bind(this);

  }


  logout(){
     localStorage.setItem("userData",'');
     localStorage.removeItem("userData")
     sessionStorage.setItem("UrlData",'');     
     sessionStorage.clear();
     
     this.setState({redirectToReferrer: true});
  } 

  componentDidMount() { 
         this.logout();  
   }

  render() {
    const { t } = this.props

    if (this.state.redirectToReferrer) {
      return (<Redirect to={'/'}/>)
    }




    return (
      <div></div>

    );
  }
}

export default withTranslation()(Logout);
