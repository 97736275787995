export default {
    items: [
      {
        name: 'Dashboard',
        url: '/dashboard',
        icon: 'icon filter',
        class:'red nav-dashboard',
        badge: {
          variant: 'info',
          text: 'NEW',
        },
      },
      {
        name: 'Clubs',
        url: '/clubs',
        icon: 'icon users',
        class:'blue nav-clubs',
      },
	  {
        name: 'Games',
        url: '/games',
        icon: 'icon chips',
        class:'green nav-games',
      }, 
      {
        name: 'Player',
        url: '/player',
        icon: 'icon user',
        class:'orange nav-player',
      },
    ] 
};
