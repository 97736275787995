
import i18n from 'i18next'
import Backend from 'i18next-xhr-backend'
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next'
import config from '../src/config';

const browserLanguage = window.navigator.language.substring(0, 2);

const userLanguage = (config.languages.includes(browserLanguage)) ? browserLanguage : "en"


console.log(`userLanguage: ${userLanguage}`)


i18n
  .use(LanguageDetector) // detect user language
  .use(Backend) // load translations using http (default public/assets/locals/en/translations)
  .use(initReactI18next) // pass the i18n instance to react-i18next.
  .init({
    lng: localStorage.getItem('language') || userLanguage,
    backend: {
      /* translation file path */
      loadPath: '/assets/i18n/{{ns}}/{{lng}}.json'
    },
    fallbackLng: 'en',
    debug: true,
    /* can have multiple namespace, in case you want to divide a huge translation into smaller pieces and load them on demand */
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ','
    },
    react: {
      useSuspense: true,
      wait: true
    }
  })


export default i18n