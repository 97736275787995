import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import {  Container } from 'reactstrap';
import Footer from '../Footer/Footer';
import { withTranslation } from 'react-i18next';


class Thankyou extends Component {

  constructor(props){
    super(props);
   
    this.state = {
      message: this.props.match.params.message || '',
    };


    this.clickOK = this.clickOK.bind(this);         

  }

  clickOK(){
    this.props.history.push("/login");
  }


  render() {
    const { t } = this.props

    return (
  <div className="wrapper is-guest-user centered-wrap">


      <section className="slogan">
        <div className="logo"></div>
        <div className="title">
          <div className="title-1">
          {t("MEET YOUR FRIENDS")}
          </div>
          <div className="title-2">
            &amp;{t("PLAY PRIVATE POKER LIVE")}
          </div>
        </div>
      </section>

            <div className="app flex-row align-items-center">
              <Container>              
                  <div className="row justify-content-center my-4 card-box no-gutters page-check-email">
                      <div className="col-sm-10 col-lg-7">
                          <div className="card-box-header text-center">{t(this.state.message)}</div>
                          <div className="card-box-body py-4">
                              <div className="row justify-content-center py-4">
                                  <div className="col-auto">
                                      <a onClick={this.clickOK} className="button green font-medium">{t("OK")}</a>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </Container>
            </div>
            <Footer home={false} register={false}/> 
        </div>

    );
  }
}

export default withTranslation()(Thankyou);
