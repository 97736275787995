import configuration from '../config';
import axios from 'axios';

const API_URL = configuration.API_URL;
const user = JSON.parse(localStorage.getItem('userData'));
if (user){
    if (user.hasOwnProperty('token')){
      var token = user.token;
    } else {
      var token = '';
    }
} else {
    var token = '';
}

axios.defaults.headers.common = {'Authorization': `crm ${token}`}

export function Fetch(type, rootes, jsondata) {
    return new Promise((resolve, reject) =>{

          switch (type) {
            case 'post': 
              axios.post( 
                API_URL + `${rootes}`,
                jsondata
                )
              .then(response => {
                  resolve(response.data);
              })
              .catch(err => {
                  reject(err);
              });
              break;
            case 'get': 
              axios.get( 
                API_URL + `${rootes}`,
                jsondata
                )
              .then(response => {
                  resolve(response.data);
              })
              .catch(err => {
                  reject(err);
              });                         
              break;
            case 'delete':
              axios.get( 
                API_URL + `${rootes}`,
                jsondata
                )
              .then(response => {
                  resolve(response.data);
              })
              .catch(err => {
                  reject(err);
              });                                                   
              break;

          }
  
      });
}