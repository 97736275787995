import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import { Container, Input } from 'reactstrap';
import {PostData} from '../../../services/PostData';
import * as rdd from 'react-device-detect';
import config from '../../../config';
import Footer from '../Footer/Footer';
import { withTranslation } from 'react-i18next';

class Login extends Component {

  constructor(){
    super();
   
    this.state = {
     email: '',
     password: '',
     redirectToReferrer: false,
     terms:false,
     new_account_email:'',
     errorform:'',
     error_form:[],
     error_message:[],
     showLogin:true,
     showforgetPassword:false,
     showemailSend:false,
    };

    this.login = this.login.bind(this);
    this.new_account = this.new_account.bind(this);
    this.onChange = this.onChange.bind(this);  
    this.changeToForgetPassword = this.changeToForgetPassword.bind(this);  
    this.reset_password = this.reset_password.bind(this); 
    this.clickOK = this.clickOK.bind(this);     

  }


reset_password(){
      var _tmp_error =[];
      var _message = [];
      var isValid = true;  
      if (this.state.password_account_email){
          
      } else {
          _tmp_error["reset"] = "reset"
          _message["reset"] = "You must enter a valid email"
          isValid = false;
      }  
      if (
        /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          this.state.password_account_email,
        )
      ) {
       // nothing
      } else 
      {
          _tmp_error["reset"] = "reset"
          _message["reset"] = "You must enter a valid email"
          isValid = false;
      }  

      if(isValid){
          var _json = {};
          _json.email = this.state.password_account_email;

        
          //console.log(_json);
            PostData('reset_password',_json).then((result) => {
             
             let responseJson = result.results;
             if(responseJson.token)
             {            
               this.setState({showLogin:false,
                              showforgetPassword:false,
                              showemailSend:true,
               })                    
             } else {
               _tmp_error["reset"] = "reset"
              _message["reset"] = "Error sending email with the link to reset your password, please contact administrator"              
                this.setState({
                      error_form:_tmp_error,
                      error_message:_message,
                    })  
             }
             
            });
      } else {
        this.setState({
              error_form:_tmp_error,
              error_message:_message,
            })         
      }           
}


changeToForgetPassword(){
  this.setState({
    showLogin:false,
    showforgetPassword:true,
    showemailSend:false,
  });
}

  clickOK(){
    this.setState({
     showLogin:true,
     showforgetPassword:false,
     showemailSend:false,
    })
  }

  new_account() {
    var _tmp_error =[];
    var _message = [];
    var isValid = true;
    
    
      if (this.state.new_account_email){
          
      } else {
          _tmp_error["registry"] = "registry"
          _message["registry"] = "You must enter a valid email"
          isValid = false;
      }


      if (
        /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          this.state.new_account_email,
        )
      ) {
       // nothing
      } else 
      {
          _tmp_error["registry"] = "registry"
          _message["registry"] = "You must enter a valid email"
          isValid = false;
      }

      if (this.state.terms) {

      } else { 
          _tmp_error["registry"] = "registry"
          _message["registry"] = "You must accept terms and conditions of service"
          isValid = false;
      }
      if(isValid){
          var _json = {};
          _json.new_account_email = this.state.new_account_email;
          _json.url_redirect = '';
          var urlRedirect = JSON.parse(sessionStorage.getItem("UrlData"));
          if (sessionStorage.getItem("UrlData")){
              if (urlRedirect.hasOwnProperty('page')){ 
                _json.url_redirect = urlRedirect.page + urlRedirect.query
              }
          }         
          
            PostData('new_account',_json).then((result) => {
             let responseJson = result.results;
             if(responseJson.token)
             {            
               this.props.history.push("/checkmail/"+responseJson.token);                     
             } else {
               _tmp_error["registry"] = "registry"
              _message["registry"] = "Email already registered, please contact administrator"              
                this.setState({
                      error_form:_tmp_error,
                      error_message:_message,
                    })  
             }
             
            });
      } else {
        this.setState({
              error_form:_tmp_error,
              error_message:_message,
            })         
      }      
  }

  login() {
    var _tmp_error =[];
    var _message = [];
    var isValid = true;    
    if(this.state.email && this.state.password){
      PostData('login',this.state).then((result) => {
       let responseJson = result;
       if(responseJson.hasOwnProperty("message")){
          _tmp_error["login"] = "login"
          _message["login"] = responseJson.message
          this.setState({
                error_form:_tmp_error,
                error_message:_message,
              }) 
       } else {
         localStorage.setItem('userData',JSON.stringify(responseJson));
         this.setState({redirectToReferrer:true})         
       }      
      });      
    }  else {    
        _tmp_error["login"] = "login"
        _message["login"] = "Username or password empty..."
        this.setState({
              error_form:_tmp_error,
              error_message:_message,
            })         
    }  
   }

  onChange(event)
  {            

      if (event.target.name==='terms'){
         this.setState({terms:!this.state.terms});

      } else {
         this.setState({[event.target.name]:event.target.value});
      }
  }


  render() {

    const { t } = this.props;
    var {showLogin,showforgetPassword,showemailSend} = this.state;

    this.changeToForgetPassword = this.changeToForgetPassword.bind(this);
    this.onChange = this.onChange.bind(this);
    
    if (sessionStorage.getItem("UrlData")){
      var urlRedirect = JSON.parse(sessionStorage.getItem("UrlData"));
        if (urlRedirect.hasOwnProperty('page'))
        {
            if ((urlRedirect.page==='/login')||(urlRedirect.page==='/')){
                if (this.state.redirectToReferrer) {
                return (<Redirect to={'/private/clubs'}/>)
              }            
            } else {
              if (this.state.redirectToReferrer) {
                return (<Redirect to={urlRedirect.page + urlRedirect.query }/>)
              } 
            }            
        } else {
          if (this.state.redirectToReferrer) {
            return (<Redirect to={'/private/clubs'}/>)
          }
        }
    } else {
           if (this.state.redirectToReferrer) {
            return (<Redirect to={'/private/clubs'}/>)
          }   
    }




        var LabelStatus = ({ type , error , message}) => {
            
            switch(type) {
              case "login":
                  if (type===error[type]){
                      return (<React.Fragment><small className="text text-center red">{t(message[type])}</small></React.Fragment>);
                  } else {
                       return (<React.Fragment/>);
                  }                
                break;
              case "registry":
                  if (type===error[type]){
                      return (<React.Fragment><small className="text text-center red">{t(message[type])}</small></React.Fragment>);
                  } else {
                       return (<React.Fragment/>);
                  } 
                break;  
              case "reset":
                  if (type===error[type]){
                      return (<React.Fragment><small className="text text-center red">{t(message[type])}</small></React.Fragment>);
                  } else {
                       return (<React.Fragment/>);
                  } 
                break;                                                                                                                                                                                                                                                
            }   
        };   

  /*
  if (rdd.isMobile){
    return (
            <div className="wrapper is-guest-user centered-wrap">
            <div className="bg-cards"></div>
              <div className="logo"></div>
              <section className="slogan">
                <div className="title">
                  <div className="title-1">
                    {t("MEET YOUR FRIENDS")}
                  </div>
                  <div className="title-2">
                    &amp;{t("PLAY PRIVATE POKER LIVE")}
                  </div>
                </div>
              </section>
              <section className="central">
              <div className="row justify-content-center my-4 card-box no-gutters page-login">
              <div className="col login d-flex flex-column justify-content-center pt-4">
               {t("We are glad you are using pokerpeek, although the current version is a beta version and, at the moment, only works properly with a computer. Please try again with a laptop or desktop computer. Many thanks")}
              </div>
              </div>
              </section>                   
             </div>           
      );
  } else */
  {

    return (
        <div className="wrapper is-guest-user centered-wrap">

                <section className="slogan">
                    <div className="logo"></div>
                      <div className="title">
                        <div className="title-1">
                          {t("MEET YOUR FRIENDS")}
                        </div>
                        <div className="title-2">
                          &amp;{t("PLAY PRIVATE POKER LIVE")}
                        </div>
                      </div>
                    </section>

                  <div className="app flex-row align-items-center">
                    <Container>
                        <div className="row justify-content-center my-4 card-box no-gutters page-login">
                         {showLogin && 
                            <div className="col-sm-10 col-lg-7">
                                <div className="card-box-header text-center">{t("Games access")}</div>
                                <div className="card-box-body py-4">

                                    <div className="title text-center font-bold h4">{t("Access to registered users")} </div>
                                    <div className="row no-gutters">
                                        <div className="col">
                                            <div className="row pt-2 pb-2 px-5">
                                                <div className="col p-1"><input type="text" defaultValue="" name="email" placeholder={t("Nickname or Email")} onChange={this.onChange} className="w-100" /></div>
                                                <div className="col p-1"><input type="password" name="password" placeholder={t("Password")} onChange={this.onChange} className="w-100" /></div>
                                                <div className="col-auto p-1">
                          <input type="submit" className="button orange" value={t("Start playing")} onClick={this.login} />
                                                {/*<a tabindex="0" className="button green" onClick={this.login}>Start playing</a>*/}
                                                </div>
                                            </div>
                      <small onClick={this.changeToForgetPassword} className="text text-center link-blue cursor-pointer mb-4 font-medium">{t("Forgot Password?")}</small>
                                        </div>                                        
                                    </div>

                                    <LabelStatus type="login" error={this.state.error_form} message={this.state.error_message}/>
                                    <div className="title pt-2 text-center font-bold h4">{t("Create a new free account")}</div>
                                    <div className="text-center text-muted font-medium">{t("You will receive a welcome email with the validation code")}</div>
                                    <small className="text-center text-muted">
                                        <div className="form-check">
                                            <input checked={this.state.terms} tabIndex="-1" onChange={this.onChange} type="checkbox" className="form-check-input" id="terms" name="terms"/>
                                             <label className="form-check-label font-medium" htmlFor="exampleCheck1">{t('I accept')} <a href={config.WEB_URL + 'legal/privacy_policy.html'}>{t("Privacy policy")}</a></label>
                                        </div>
                                    </small>
                                    <div className="row no-gutters">
                                        <div className="col-8 offset-2">
                                            <div className="row py-2 px-5">
                                                <div className="col p-1"><input type="text" onChange={this.onChange} name="new_account_email" placeholder={t("Email")} autoComplete="email" className="w-100"  /></div>
                                                <div className="col-auto p-1"><a onClick={this.new_account} className="button orange font-medium">{t("New account")}</a></div>
                                            </div> 
                                        </div>
                                    </div>
                                    <LabelStatus type="registry" error={this.state.error_form} message={this.state.error_message}/>
                                </div>
                            </div>
                          }
                          {showforgetPassword && 

                            <div className="col-sm-10 col-lg-7">
                                <div className="card-box-header text-center">{t("Change Password")}</div>
                                <div className="card-box-body py-4">

                                   <div className="row no-gutters">
                                        <div className="col-10 offset-1 text-center">


                                                    {t("Introduce your email to receive a link that lets you create a new password")}.
                                                    <small className="mt-4 text-muted">{t("Check your spam folder if you can't find the email in your main folder.")}</small>

                                            <div className="row py-2 px-5">
                                                <div className="col p-1"><input type="text" onChange={this.onChange} name="password_account_email" placeholder={t("Email")} autoComplete="email" className="w-100"  /></div>
                                                <div className="col-auto p-1"><a onClick={this.reset_password} className="button orange">{t("Send")}</a></div>
                                            </div> 
                                        </div>
                                    </div>    

                                    <LabelStatus type="reset" error={this.state.error_form} message={this.state.error_message}/>                                    
                                </div>
                            </div>
                          }

                          {showemailSend && 
                                <div className="card-box-body py-4">
                                    <div className="row">
                                        <div className="col-10 offset-1 text-muted">
                                            {t("We have sent an email to")} <span className="text green">{this.state.password_account_email}</span> {t("with a link to reset your password")}. 
                                        </div>
                                    </div>

                                    <div className="row justify-content-center py-4">
                                        <div className="col-auto">
                    <a onClick={this.clickOK} className="button orange">{t("OK")}</a>
                                        </div>
                                    </div>
                                </div>    
                          }
                        </div>

                </Container>
              </div>
                <Footer home={false} register={false}/>
           </div>

    );
   }
  }
}




export default withTranslation()(Login);
