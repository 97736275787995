import React, { Component, Suspense } from 'react';
import { BrowserRouter, HashRouter, Route, Switch } from 'react-router-dom';
import './App.scss';

// Containers
import { DefaultLayout } from './containers';
// Pages
import { Login, Page404, Page500, Register, Checkmail, Thankyou, RegisterStep2, RegisterStep3, Change_Password, Logout, Home } from './views/Pages';
import { renderRoutes } from 'react-router-config';

 


class App extends Component {
  render() {
    return (
      <Suspense fallback={<div></div>} maxDuration={5000}>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" name="Home Page" component={Home} />
          <Route exact path="/login" name="Login Page" component={Login} />
          <Route exact path="/logout" name="Login Page" component={Logout} />
          <Route exact path="/thankyou/:message" name="Thank You Page" component={Thankyou} />
          <Route exact path="/checkmail/:token" name="Thank You Page" component={Checkmail} />
          <Route exact path="/change_password/:token" name="Change Password Page" component={Change_Password} />
          
          <Route exact path="/register/:token" name="Register Page" component={Register} />
          <Route exact path="/registerstep2/:token" name="Register Page" component={RegisterStep2} />
          <Route exact path="/registerstep3/:token" name="Register Page" component={RegisterStep3} />
          <Route exact path="/404" name="Page 404" component={Page404} />
          <Route exact path="/500" name="Page 500" component={Page500} />
          <Route path="/private" name="Private" component={DefaultLayout} />
        </Switch>
      </BrowserRouter>
      </Suspense>
    );
  }
}

export default App;
