import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import { Container, Input } from 'reactstrap';
import {PostData} from '../../../services/PostData';
import Slider from "react-slick";
import * as rdd from 'react-device-detect';
import config from '../../../config';
import Cookies from '../Cookies/Cookies';
import Footer from '../Footer/Footer';
import { withTranslation } from 'react-i18next';


class Home extends Component {

  constructor(){
    super();
   
    this.state = {
     email: '',
     password: '',
     redirectToReferrer: false,
     terms:false,
     new_account_email:'',
     errorform:'',
     error_form:[],
     error_message:[],
     showLogin:true,
     showforgetPassword:false,
     showemailSend:false,
    };

    this.login = this.login.bind(this);
    this.new_account = this.new_account.bind(this);
    this.onChange = this.onChange.bind(this);  
    this.changeToForgetPassword = this.changeToForgetPassword.bind(this);  
    this.reset_password = this.reset_password.bind(this); 
    this.clickOK = this.clickOK.bind(this); 
    this.goback = this.goback.bind(this);    

  }


goback(){
  this.setState({showLogin:true,showforgetPassword:false});
}


reset_password(){
      var _tmp_error =[];
      var _message = [];
      var isValid = true;  
      if (this.state.password_account_email){
          
      } else {
          _tmp_error["reset"] = "reset"
          _message["reset"] = "You must enter a valid email"
          isValid = false;
      }  
      if (
        /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          this.state.password_account_email,
        )
      ) {
       // nothing
      } else 
      {
          _tmp_error["reset"] = "reset"
          _message["reset"] = "You must enter a valid email"
          isValid = false;
      }  

      if(isValid){
          var _json = {};
          _json.email = this.state.password_account_email;

        
          //console.log(_json);
            PostData('reset_password',_json).then((result) => {
             
             let responseJson = result.results;
             if(responseJson.token)
             {            
               this.setState({showLogin:false,
                              showforgetPassword:false,
                              showemailSend:true,
               })                    
             } else {
               _tmp_error["reset"] = "reset"
              _message["reset"] = responseJson             
                this.setState({
                      error_form:_tmp_error,
                      error_message:_message,
                    })  
             }
             
            });
      } else {
        this.setState({
              error_form:_tmp_error,
              error_message:_message,
            })         
      }           
}


changeToForgetPassword(){
  this.setState({
    showLogin:false,
    showforgetPassword:true,
    showemailSend:false,
  });
}

  clickOK(){
    this.setState({
     showLogin:true,
     showforgetPassword:false,
     showemailSend:false,
    })
  }

  new_account() {
    var _tmp_error =[];
    var _message = [];
    var isValid = true;
    
    
      if (this.state.new_account_email){
          
      } else {
          _tmp_error["registry"] = "registry"
          _message["registry"] = "You must enter a valid email"
          isValid = false;
      }


      if (
        /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          this.state.new_account_email,
        )
      ) {
       // nothing
      } else 
      {
          _tmp_error["registry"] = "registry"
          _message["registry"] = "You must enter a valid email"
          isValid = false;
      }

      if (this.state.terms) {

      } else { 
          _tmp_error["registry"] = "registry"
          _message["registry"] = "You must accept terms and conditions of service"
          isValid = false;
      }
      if(isValid){
          var _json = {};
          _json.new_account_email = this.state.new_account_email;
          _json.url_redirect = '';
          var urlRedirect = JSON.parse(sessionStorage.getItem("UrlData"));
          if (sessionStorage.getItem("UrlData")){
              if (urlRedirect.hasOwnProperty('page')){ 
                _json.url_redirect = urlRedirect.page + urlRedirect.query
              }
          }         
          
            PostData('new_account',_json).then((result) => {
             let responseJson = result.results;
             if(responseJson.token)
             {            
               this.props.history.push("/checkmail/"+responseJson.token);                     
             } else {
               _tmp_error["registry"] = "registry"
              _message["registry"] = "Email already registered, please contact administrator"              
                this.setState({
                      error_form:_tmp_error,
                      error_message:_message,
                    })  
             }
             
            });
      } else {
        this.setState({
              error_form:_tmp_error,
              error_message:_message,
            })         
      }      
  }

  login() {
    var _tmp_error =[];
    var _message = [];
    var isValid = true;    
    if(this.state.email && this.state.password){
      PostData('login',this.state).then((result) => {
       let responseJson = result;
       if(responseJson.hasOwnProperty("message")){
          _tmp_error["login"] = "login"
          _message["login"] = responseJson.message
          this.setState({
                error_form:_tmp_error,
                error_message:_message,
              }) 
       } else {
         localStorage.setItem('userData',JSON.stringify(responseJson));
         this.setState({redirectToReferrer:true})         
       }      
      });      
    }  else {    
        _tmp_error["login"] = "login"
        _message["login"] = "Username or password empty..."
        this.setState({
              error_form:_tmp_error,
              error_message:_message,
            })         
    }  
   }

  onChange(event)
  {            
      if (event.target.name==='terms'){
         this.setState({terms:!this.state.terms});

      } else {
         this.setState({[event.target.name]:event.target.value});
      }
  }


  render() {
    const { t } = this.props;
      const settings = {
              infinite: true,
              centerMode: true,
              autoplay:true,
              dots: true,
              autoplaySpeed:4000,
              arrows:true,
              centerPadding: '300px',
              slidesToShow: 1,
              cssEase: "linear",
              pauseOnHover:true,
              adaptiveHeight: true,
              responsive: [
                {
                  breakpoint: 1600,
                  settings: {
                    centerPadding: '250px'
                  }
                },
                  {
                  breakpoint: 1500,
                  settings: {
                    centerPadding: '200px'
                  }
                },
                  {
                  breakpoint: 1400,
                  settings: {
                    centerPadding: '150px'
                  }
                },
                {
                  breakpoint: 1300,
                  settings: {

                    centerPadding: '100px'
                  }
                  },
                  {
                  breakpoint: 1024,
                  settings: {
                    centerPadding: '50px'
                  }
                }
              ]
      };


   var {showLogin,showforgetPassword,showemailSend} = this.state;

    this.changeToForgetPassword = this.changeToForgetPassword.bind(this);
    this.onChange = this.onChange.bind(this);
    
    if (sessionStorage.getItem("UrlData")){
      var urlRedirect = JSON.parse(sessionStorage.getItem("UrlData"));
        if (urlRedirect.hasOwnProperty('page'))
        {
            if ((urlRedirect.page==='/login')||(urlRedirect.page==='/')){
                if (this.state.redirectToReferrer) {
                return (<Redirect to={'/private/clubs'}/>)
              }            
            } else {
              if (this.state.redirectToReferrer) {
                return (<Redirect to={urlRedirect.page + urlRedirect.query }/>)
              } 
            }            
        } else {
          if (this.state.redirectToReferrer) {
            return (<Redirect to={'/private/clubs'}/>)
          }
        }
    } else {
           if (this.state.redirectToReferrer) {
            return (<Redirect to={'/private/clubs'}/>)
          }   
    }

    class LanguageSelector extends React.Component {
      constructor() {
        super();

        this.state = {
          droppedDown: false,
        }

        this.chooseLanguage = this.chooseLanguage.bind(this)
        this.openMenu = this.openMenu.bind(this)
      } 

      openMenu() {
        console.log("trying to open menu")
        this.setState({droppedDown: true})
      }

      chooseLanguage(lang) {
        this.setState({droppedDown: false})
        localStorage.setItem('language', lang)
        window.location.reload();
      }

      render() {
        let {droppedDown} = this.state
        let containerCSSClass = droppedDown ? "lang-selector-container selected": "lang-selector-container"
        let buttonCSSClass = droppedDown ? "btn-down selected" : "btn-down"
        const arrLanguages = config.languages
        const browserLanguage = window.navigator.language.substring(0, 2);

        const userLanguage = (arrLanguages.includes(browserLanguage)) ? browserLanguage : "en"

        let lng = localStorage.getItem('language') === null ? userLanguage : localStorage.getItem('language')

        return (
          <>
            <div className={containerCSSClass}>
            <div className="lang-selector">
              <div className="lang"><strong>{lng.toUpperCase()}</strong></div>
              <div className={buttonCSSClass} onClick={() => this.openMenu()}><div className="dropdownimg"/></div>
            </div>
              <ul>
                {
                  arrLanguages.map((lng, key) => {
                    return (<li onClick={() => this.chooseLanguage(lng)} key={key}><strong>{lng.toUpperCase()}</strong></li>)
                  })
                }

            </ul>
            </div>
          </>
        )
  
      }
    }


        var LabelStatus = ({ type , error , message}) => {
            
            switch(type) {
              case "login":
                  if (type===error[type]){
                      return (<React.Fragment><small className="text text-center red">{t(message[type])}</small></React.Fragment>);
                  } else {
                       return (<React.Fragment/>);
                  }                
                break;
              case "registry":
                  if (type===error[type]){
                      return (<React.Fragment><small className="text text-center red">{t(message[type])}</small></React.Fragment>);
                  } else {
                       return (<React.Fragment/>);
                  } 
                break;  
              case "reset":
                  if (type===error[type]){
                      return (<React.Fragment><small className="text text-center red">{t(message[type])}</small></React.Fragment>);
                  } else {
                       return (<React.Fragment/>);
                  } 
                break;                                                                                                                                                                                                                                                
            }   
        };  

        
  if (rdd.isMobile){
    return (
      <div className="wrapper is-guest-user centered-wrap page-home">

               <section className="slogan">
                  <div className="logo"></div>
                  <div className="title">
                    <div className="title-1">
                      {t('MEET YOUR FRIENDS')}
                    </div>
                    <div className="title-2">
                      &amp;{t('PLAY PRIVATE POKER LIVE')}
                    </div>
                  </div>
                  <LanguageSelector lang="en"/>
                    
                  
         </section>

                <div className="app flex-row align-items-center">
                  <Container className="extra">
      <section className="central">
          <div className="help do-this">
            <div className="lights">
               <h2 className="title">{t('What do I need to do?')}</h2>
            <ul>
              <li>
                <div>1</div>
                {t('Create a Free Account')}
              </li>
              <li>
                <div>2</div>
                {t('Start or join a Private Club')}
              </li>                
              <li>
                <div>3</div>
                {t('Set up a Tournament')}
              </li>
               <li>
                 <strong>{t('Share the Link & Have Fun!')}</strong>
              </li>
            </ul>

          </div>
          <div className="dont-do-this">{t("You don't need to install any software.")}</div>

      </div>



       <div className="row justify-content-center my-4 card-box no-gutters do-login login-box">
                     {showLogin && 
                          <div className="col login gold-light">
                              <div className="text-center h4 title">{t('Create a free account')}</div>
                              <div className="card-box-body p-0">


                                  <div className="row no-gutters">

                                          <div className="row py-2 px-5 mt-n4">
                                              <div className="text-center text-muted small w-100">{t('You will receive an email with the validation code')}</div>
                                              <small className="text-center text-muted mx-auto">
                                                  <div className="form-check">
                                                      <input checked={this.state.terms} tabIndex="-1" onChange={this.onChange} type="checkbox" className="form-check-input" id="terms" name="terms"/>
                                                      <label className="form-check-label" htmlFor="exampleCheck1">{t('I accept')} <a href={config.WEB_URL + 'legal/privacy_policy.html'}>{t('Privacy policy')}</a></label>
                                                  </div>
                                              </small>
                                              <div className="w-100 p-1"><input type="text" onChange={this.onChange} name="new_account_email" placeholder={t('Email')} autoComplete="email" className="w-100"  /></div>
                                              <div className="w-100 p-1"><a onClick={this.new_account} className="button gradient-orange w-100">{t('Create account')}</a></div>
                                              <LabelStatus type="registry" error={this.state.error_form} message={this.state.error_message}/>
                                          </div>

                                  </div>



                                  <div className="title pt-2 mt-2 text-center h4">{t('Game access')}</div>
                                  <div className="sep gold-light"></div>

                                   <div className="row no-gutters">
                                      <div className="col">
                                          <div className="row py-2 px-5">

                                              <div className="p-1 w-100"><input type="text" defaultValue="" name="email" placeholder={t('Nickname or Email')} onChange={this.onChange} className="w-100" /></div>
                                              <div className="p-1 w-100"><input type="password" name="password" placeholder={t('Password')} onChange={this.onChange} className="w-100" /></div>
                                              <div className="p-1 w-100">
                                              <input type="submit" className="button gradient-blue w-100" value={t('Start playing')} onClick={this.login}/>
                                              {/*<a tabindex="0" className="button green" onClick={this.login}>Start playing</a>*/}
                                              </div>
                                              <div className="w-100">
                                               <small onClick={this.changeToForgetPassword} className="text-left cursor-pointer ml-1">{t('Forgot password?')}</small>
                                              </div>
                                              <LabelStatus type="login" error={this.state.error_form} message={this.state.error_message}/>
                                          </div>

                                      </div>
                                  </div>
                              </div>
                          </div>
                        } 
                       {showforgetPassword &&
                          <div className="col login gold-light password-recovery">
                              <div className="title text-center h4">{t('Password recovery')}</div>
                              <div className="card-box-body px-5">

                                <div className="row no-gutters">

                                  <div className="text-center text-muted">{t('Introduce your email to receive a link that lets you create a new password')}.
                                    <small className="mt-4 text-muted">{t("Check your spam folder if you can't find the email in your main folder.")}</small>
                                  </div>


                                        <div className="py-2 w-100">
                                            <div className="w-100 p-1"><input type="text" onChange={this.onChange} name="password_account_email" placeholder="Email" autoComplete="email" className="w-100"  /></div>
                                            <div className="w-100 p-1"><a onClick={this.reset_password} className="button gradient-blue w-100">{t("Reset Password")}</a></div>
                                        </div>


                                  <LabelStatus type="reset" error={this.state.error_form} message={this.state.error_message}/>
                                <div className="title text-center w-100"><a onClick={this.goback} className="button gradient-gray w-100">{t("Back")}</a></div>
                                </div>

                              </div>
                          </div>

                        }

                        {showemailSend &&
                              <div className="col login d-flex flex-column justify-content-center pt-4">
                                  <div className="row">
                                      <div className="col-10 offset-1 text-muted">
                                          {t("We have sent an email to")} <span className="text green">{this.state.password_account_email}</span> {t("with a link to reset your password")}.
                                      </div>
                                  </div>

                                  <div className="row justify-content-center py-4">
                                      <div className="col-auto">
                      <a onClick={this.clickOK} className="button orange">{t("OK")}</a>
                                      </div>
                                  </div>
                              </div>
                        }
          </div>



         {/*   <div className="help dont-do-this">
            <h3 className="title">What don't I need to do?</h3>
            <ul>
              <li>
                <div>1</div>
                Install any software
              </li>
              <li>
                <div>2</div>
                Use any external streaming software
              </li>
              <li>
                <div>3</div>
                Pay if you only play occasionally
              </li>
            </ul>
          </div>
        <!-- dont-do-this --> */}
        </section>


      {/*<div className="slider center" >
                  <div>
                      <img src="/home/slide.jpg" />
                      <h3>Get unlimited tables for your own games until 9 players</h3>
                  </div>
                  <div>
                      <img src="poker_screenshot_audience.jpg" />
                      <h3>The audience and eliminated players can stay at the tables to look and chat</h3>
                  </div>
                  <div>
                      <img src="poker_screenshot_stats.jpg" />
                      <h3>Odds support for beginners</h3>
                  </div>
                  <div>
                      <img src="poker_screenshot_AR_masks.jpg" />
                      <h3>Extra fun with AR filters</h3>
                  </div>
         </div>*/}

      <h3>{t("Poker made fun")}</h3>
              <Slider className="slider center" {...settings}>
                 <div>
                      <img src="/home/slide.jpg" />
                      <h4>{t("Get unlimited tables for your own games of up to 9 players per table")}</h4>
                  </div>
                 <div>
                      <img src="/home/poker_screenshot_audience.jpg" />
                      <h4>{t("The audience and eliminated players can stay at the tables to look and chat")}</h4>
                  </div>
                  <div>
                      <img src="/home/poker_screenshot_stats.jpg" />
                      <h4>{t("Odds support for beginners")}</h4>
                  </div>
                  <div>
                      <img src="/home/poker_screenshot_AR_masks.jpg" />
                      <h4>{t("Extra fun with AR filters")}</h4>
                  </div>
              </Slider>


      <section className="reasons">
          <h3>{t("Our poker hand")}</h3>
          <div className="reasons-inner gold-light">
            <div className="poker-hand"></div>
            <div className="benefits">
              <ul>
                <li>
                  <strong>{t("Easy")}</strong> <span>{t("just share the game link with your friends before the game starts")}</span>
                </li>
                <li>
                  <strong>{t("Amusing")}</strong>  <span>{t("feel like you are playing in the room together, with special in-game effects and much more")} </span>
                </li>
                <li>
                  <strong>{t("Very affordable")}</strong>  <span>{t("play the occasional game for free or pay peanuts for extra fun")} </span>
                </li>
                <li>
                  <strong>{t("You")}</strong>  <span>{t("are the key and we are committed to providing you the best possible social poker game experience")} </span>
                </li>
              </ul>
            </div>
          </div>
        </section>

          <section className="requirements">
              <h3>{t('Requirements')}</h3>
              <>
              <div className="card-box">
                <div className="justify-content-center">
                  {t('We are glad you are using pokerpeek, although the current version is a beta version and, at the moment, only works properly with a computer. Please try again with a laptop or desktop computer. Many thanks')}
                </div>
              </div>
              </>
              <div className="requirements-inner">
                  <div>
                      <div className="requirements-cell adults image">
                      <img src="/assets/img/crm/adults-only.png" />
                      </div>
                      <strong className="requirements-cell adults text">{t("Adults only")}</strong>
                      <div className="requirements-cell computer image">
                      <img src="/assets/img/crm/responsive-design.png" />
                      </div>
                      <strong className="requirements-cell computer text">{t("We recommend playing the current version on laptops or desktops, rather than tablets or phones")}</strong>
                      <div className="requirements-cell webcam image">
                      <img src="/assets/img/crm/webcam.png" />
                      </div>
                      <strong className="requirements-cell webcam text">{t("You need a webcam")}</strong>
                      <div className="requirements-cell browsers image">
                      <img src="/assets/img/crm/browsers.png" />
                      </div>
                      <strong className="requirements-cell browsers text">{t("Access our games via")}<br />{t("Chrome, Firefox or Edge only")}</strong>
                  </div>
                  <div className="two-cards"></div>
              </div>
          </section>
        <Footer home={true} register={false}/> 
        <Cookies />                
              </Container>
            </div>
          </div>


  );
  } else 
  {


    return (
        <div className="wrapper is-guest-user centered-wrap page-home">

                 <section className="slogan">
                    <div className="logo"></div>
                    <div className="title">
                      <div className="title-1">
                        {t('MEET YOUR FRIENDS')}
                      </div>
                      <div className="title-2">
                        &amp;{t('PLAY PRIVATE POKER LIVE')}
                      </div>
                    </div>
                    <LanguageSelector lang="en"/>
                      
                    
			     </section>

                  <div className="app flex-row align-items-center">
                    <Container className="extra">

        <section className="central neon-blink">

            <div className="help do-this">
              <div className="lights">
                 <h2 className="title">{t('What do I need to do?')}</h2>
              <ul>
                <li>
                  <div>1</div>
                  {t('Create a Free Account')}
                </li>
                <li>
                  <div>2</div>
                  {t('Start or join a Private Club')}
                </li>                
                <li>
                  <div>3</div>
                  {t('Set up a Tournament')}
                </li>
                 <li>
                   <strong>{t('Share the Link & Have Fun!')}</strong>
                </li>
              </ul>

            </div>
            <div className="dont-do-this">{t("You don't need to install any software.")}</div>
            <span className="neonbg1 neon-blink"></span>
            <span className="neonbg2">
                    <div className="funfilters">
                        <div>Includes</div>
                        <div>Fun filters</div>
                    </div>
                </span>
        </div>



         <div className="row justify-content-center my-4 card-box no-gutters do-login login-box">
                       {showLogin && 
                            <div className="col login gold-light">
                                <div className="text-center h4 title">{t('Create a free account')}</div>
                                <div className="card-box-body p-0">


                                    <div className="row no-gutters">

                                            <div className="row py-2 px-5 mt-n4">
                                                <div className="text-center text-muted small w-100">{t('You will receive an email with the validation code')}</div>
                                                <small className="text-center text-muted mx-auto">
                                                    <div className="form-check">
                                                        <input checked={this.state.terms} tabIndex="-1" onChange={this.onChange} type="checkbox" className="form-check-input" id="terms" name="terms"/>
                                                        <label className="form-check-label" htmlFor="exampleCheck1">{t('I accept')} <a href={config.WEB_URL + 'legal/privacy_policy.html'}>{t('Privacy policy')}</a></label>
                                                    </div>
                                                </small>
                                                <div className="w-100 p-1"><input type="text" onChange={this.onChange} name="new_account_email" placeholder={t('Email')} autoComplete="email" className="w-100"  /></div>
                                                <div className="w-100 p-1"><a onClick={this.new_account} className="button gradient-orange w-100">{t('Create account')}</a></div>
                                                <LabelStatus type="registry" error={this.state.error_form} message={this.state.error_message}/>
                                            </div>

                                    </div>



                                    <div className="title pt-2 mt-2 text-center h4">{t('Game access')}</div>
                                    <div className="sep gold-light"></div>

                                     <div className="row no-gutters">
                                        <div className="col">
                                            <div className="row py-2 px-5">

                                                <div className="p-1 w-100"><input type="text" defaultValue="" name="email" placeholder={t('Nickname or Email')} onChange={this.onChange} className="w-100" /></div>
                                                <div className="p-1 w-100"><input type="password" name="password" placeholder={t('Password')} onChange={this.onChange} className="w-100" /></div>
                                                <div className="p-1 w-100">
                                                <input type="submit" className="button gradient-blue w-100" value={t('Start playing')} onClick={this.login}/>
                                                {/*<a tabindex="0" className="button green" onClick={this.login}>Start playing</a>*/}
                                                </div>
                                                <div className="w-100">
                                                 <small onClick={this.changeToForgetPassword} className="text-left cursor-pointer ml-1">{t('Forgot password?')}</small>
                                                </div>
                                                <LabelStatus type="login" error={this.state.error_form} message={this.state.error_message}/>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                          } 
                         {showforgetPassword &&
                            <div className="col login gold-light password-recovery">
                                <div className="title text-center h4">{t('Password recovery')}</div>
                                <div className="card-box-body px-5">

                                  <div className="row no-gutters">

                                    <div className="text-center text-muted">{t('Introduce your email to receive a link that lets you create a new password')}.
                                      <small className="mt-4 text-muted">{t("Check your spam folder if you can't find the email in your main folder.")}</small>
                                    </div>


                                          <div className="py-2 w-100">
                                              <div className="w-100 p-1"><input type="text" onChange={this.onChange} name="password_account_email" placeholder="Email" autoComplete="email" className="w-100"  /></div>
                                              <div className="w-100 p-1"><a onClick={this.reset_password} className="button gradient-blue w-100">{t("Reset Password")}</a></div>
                                          </div>


                                    <LabelStatus type="reset" error={this.state.error_form} message={this.state.error_message}/>
                                  <div className="title text-center w-100"><a onClick={this.goback} className="button gradient-gray w-100">{t("Back")}</a></div>
                                  </div>

                                </div>
                            </div>

                          }

                          {showemailSend &&
                                <div className="col login d-flex flex-column justify-content-center pt-4">
                                    <div className="row">
                                        <div className="col-10 offset-1 text-muted">
                                            {t("We have sent an email to")} <span className="text green">{this.state.password_account_email}</span> {t("with a link to reset your password")}.
                                        </div>
                                    </div>

                                    <div className="row justify-content-center py-4">
                                        <div className="col-auto">
                      <a onClick={this.clickOK} className="button orange">{t("OK")}</a>
                                        </div>
                                    </div>
                                </div>
                          }
            </div>



           {/*   <div className="help dont-do-this">
              <h3 className="title">What don't I need to do?</h3>
              <ul>
                <li>
                  <div>1</div>
                  Install any software
                </li>
                <li>
                  <div>2</div>
                  Use any external streaming software
                </li>
                <li>
                  <div>3</div>
                  Pay if you only play occasionally
                </li>
              </ul>
            </div>
          <!-- dont-do-this --> */}
          </section>


        {/*<div className="slider center" >
                    <div>
                        <img src="/home/slide.jpg" />
                        <h3>Get unlimited tables for your own games until 9 players</h3>
                    </div>
                    <div>
                        <img src="poker_screenshot_audience.jpg" />
                        <h3>The audience and eliminated players can stay at the tables to look and chat</h3>
                    </div>
                    <div>
                        <img src="poker_screenshot_stats.jpg" />
                        <h3>Odds support for beginners</h3>
                    </div>
                    <div>
                        <img src="poker_screenshot_AR_masks.jpg" />
                        <h3>Extra fun with AR filters</h3>
                    </div>
			     </div>*/}

        <h3>{t("Poker made fun")}</h3>
                <Slider className="slider center" {...settings}>
                   <div>
                        <img src="/home/slide.jpg" />
                        <h4>{t("Get unlimited tables for your own games of up to 9 players per table")}</h4>
                    </div>
                   <div>
                        <img src="/home/poker_screenshot_audience.jpg" />
                        <h4>{t("The audience and eliminated players can stay at the tables to look and chat")}</h4>
                    </div>
                    <div>
                        <img src="/home/poker_screenshot_stats.jpg" />
                        <h4>{t("Odds support for beginners")}</h4>
                    </div>
                    <div>
                        <img src="/home/poker_screenshot_AR_masks.jpg" />
                        <h4>{t("Extra fun with AR filters")}</h4>
                    </div>
                </Slider>


        <section className="reasons">
            <h3>{t("Our poker hand")}</h3>
            <div className="reasons-inner gold-light">
              <div className="poker-hand"></div>
              <div className="benefits">
                <ul>
                  <li>
                    <strong>{t("Easy")}</strong> <span>{t("just share the game link with your friends before the game starts")}</span>
                  </li>
                  <li>
                    <strong>{t("Amusing")}</strong>  <span>{t("feel like you are playing in the room together, with special in-game effects and much more")} </span>
                  </li>
                  <li>
                    <strong>{t("Very affordable")}</strong>  <span>{t("play the occasional game for free or pay peanuts for extra fun")} </span>
                  </li>
                  <li>
                    <strong>{t("You")}</strong>  <span>{t("are the key and we are committed to providing you the best possible social poker game experience")} </span>
                  </li>
                </ul>
              </div>
            </div>
          </section>

            <section className="requirements">
                <h3>{t('Requirements')}</h3>
                
                <div className="requirements-inner">
                    <div>
                        <div className="requirements-cell adults image">
                        <img src="/assets/img/crm/adults-only.png" />
                        </div>
                        <strong className="requirements-cell adults text">{t("Adults only")}</strong>
                        <div className="requirements-cell computer image">
                        <img src="/assets/img/crm/responsive-design.png" />
                        </div>
                        <strong className="requirements-cell computer text">{t("We recommend playing the current version on laptops or desktops, rather than tablets or phones")}</strong>
                        <div className="requirements-cell webcam image">
                        <img src="/assets/img/crm/webcam.png" />
                        </div>
                        <strong className="requirements-cell webcam text">{t("You need a webcam")}</strong>
                        <div className="requirements-cell browsers image">
                        <img src="/assets/img/crm/browsers.png" />
                        </div>
                        <strong className="requirements-cell browsers text">{t("Access our games via")}<br />{t("Chrome, Firefox or Edge only")}</strong>
                    </div>
                    <div className="two-cards"></div>
                </div>
            </section>
          <Footer home={true} register={false}/> 
          <Cookies />                
                </Container>
              </div>
            </div>


    );
  }
  }
}


export default withTranslation()(Home);
