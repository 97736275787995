import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/ie11'; // For IE 11 support
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {IntlProvider, addLocaleData} from "react-intl";
import es from "react-intl/locale-data/es";
import fr from "react-intl/locale-data/fr";
import en from "react-intl/locale-data/en";
import de from "react-intl/locale-data/de";
import it from "react-intl/locale-data/it";
import nl from "react-intl/locale-data/nl";

import './assets/fonts/futura-bold-bt.ttf';
import './assets/fonts/futura-medium-condensed-bt.ttf';
import './assets/fonts/futura-condensed-bold.ttf';
import './assets/fonts/Futura-Medium.ttf';
import './assets/fonts/futura-pt-cond-book.ttf';
import './assets/fonts/FuturaBT-BoldCondensed.ttf';


import messages_es from "./translations/es.json";
import messages_fr from "./translations/fr.json";
import messages_en from "./translations/en.json";
import messages_de from "./translations/de.json";
import messages_it from "./translations/it.json";
import messages_nl from "./translations/nl.json";
import i18n from './i18n'


addLocaleData([...es, ...fr, ...en, ...de, ...it, ...nl]);
const messages = {
    'es': messages_es,
    'fr': messages_fr,
    'en': messages_en,
    'de': messages_de,
    'it': messages_it,
    'nl': messages_nl
};
const language = navigator.language.split(/[-_]/)[0]; 



ReactDOM.render(<IntlProvider defaultLocale={language} locale={language} messages={messages[language]}><App /></IntlProvider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
