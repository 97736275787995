import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import {  Container } from 'reactstrap';
import {PostData} from '../../../services/PostData';
import config from '../../../config';
import Footer from '../Footer/Footer';
import { withTranslation } from 'react-i18next';

class Checkmail extends Component {

  constructor(props){
    super(props);
   
    this.state = {
      token: this.props.match.params.token || '',
      email:'',
    };

    this.getMailTmp = this.getMailTmp.bind(this); 
    this.clickOK = this.clickOK.bind(this);        

  }

  getMailTmp(token){
            PostData('getMailByTokenTmp',{"token":token}).then((result) => {
              
             let responseJson = result.results;
             
             if(responseJson.email)
             {         
    
               this.setState({email:responseJson.email})                    
             } else {
                this.props.history.push("/login");
             }
             
            });    
  }

  clickOK(){
    this.props.history.push("/login");
  }

  componentDidMount() {      
    this.getMailTmp(this.state.token);              
  }
 

  render() {
    const { t } = this.props;
    return (
        <div className="wrapper is-guest-user centered-wrap">


              <section className="slogan">
                <div className="logo"></div>
                    <div className="title">
                      <div className="title-1">
                        {t("MEET YOUR FRIENDS")}
                      </div>
                      <div className="title-2">
                        &amp;{t("PLAY PRIVATE POKER LIVE")}
                      </div>
                    </div>
                </section>

                  <div className="app flex-row align-items-center">
                    <Container>
                        <div className="row justify-content-center my-4 card-box no-gutters page-check-email">
                            <div className="col-sm-10 col-lg-7">
                                <div className="card-box-header text-center">{t("Access to games")}</div>
                                <div className="card-box-body py-4">
                                    <div className="title text-center h2 py-4 font-bold">{t("Check your email")}</div>
                                    <div className="row">
                                        <div className="col-10 offset-1 text-muted font-medium">
                                            {t("We have sent an email to")} <span className="text green">{this.state.email}</span> {t("with a link to validate your account. Use the link to finalize your creation account process")}
                                        </div>
                                    </div>
                                    <div className="row justify-content-center py-4">
                                        <div className="col-auto">
                      <a onClick={this.clickOK} className="button orange">{t("OK")}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </Container>
              </div>
               <Footer home={false} register={false}/>       
            </div>

    );
  }
}

export default withTranslation()(Checkmail);
