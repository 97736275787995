import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import {PostData} from '../../../services/PostData';
import {Fetch} from '../../../services/Fetch';
import { Container } from 'reactstrap';
import Select from 'react-select';
import moment from 'moment';
import Footer from '../Footer/Footer';
import { withTranslation } from 'react-i18next';




class Change_Password extends Component {

  constructor(props){
    super(props);
   
    const {t} = this.props
    this.state = {
     name:'',
     surname:'',
     nickname:'', 
     username: '',
     password: '',
     repassword:'',
     sex:'',
     option_sex:[
                  { value : '',label: t('Choose your gender')},
                  { value : 'male',label: t('male')},
                  { value : 'female',label: t('female')},
                  { value : 'other',label: t('other')},
                 ],
     age:'',
     email: '',
     iso_country: '',
     language_iso_code:'',
     optionCountry:[{label:t("Choose your Country"),value:''}],             
     optionLanguage:[{label:t("Choose your Language"),value:''}],
     bb_day:'',
     token:this.props.match.params.token || '',
     error_form:[],
     error_message:[],
    };

    this.signup = this.signup.bind(this);
    this.onChange = this.onChange.bind(this);
    this.getMailTmp = this.getMailTmp.bind(this);
    this.onClickSelect = this.onClickSelect.bind(this);

  }



 
  getMailTmp(token){

            var _dateNow = new Date();

            PostData('getMailByTokenResetPass',{"token":token}).then((result) => {
              
             let responseJson = result.results;
             if(responseJson.email)
             {         
                var min = Math.floor(( _dateNow.getTime() - new Date(responseJson.created_at).getTime()) / (1000 * 60 * 60));
                if (min > 1000){
                  this.props.history.push("/thankyou/The link has expired");
                }  else {                
                  this.setState({email:responseJson.email})
                }              

                                   
             } else {
                this.props.history.push("/thankyou/The link has expired");
             }
             
            });    
  }

  signup() {
    var isValid = true;
    var _tmp_error =[];
    var _message = [];
    var _dateNow = new Date();




    if (this.state.password){

      if (this.state.password.length < 6)
      {
        _tmp_error["password"] = "password"
        _message["password"] = "Password must contain at least 6 characters"
        isValid = false;       
      }
        
    } else {
        _tmp_error["password"] = "password"
        _message["password"] = "Password must contain letters"
        isValid = false;
    } 
    if (this.state.repassword){
        
    } else {
        _tmp_error["repassword"] = "repassword"
        _message["repassword"] = "Please confirm your password"
        isValid = false;
    }
 

    if ((this.state.password)&&(this.state.repassword)&&(this.state.password===this.state.repassword)) {
        
    } else 
    {
        _tmp_error["repassword"] = "repassword";
        _message["repassword"] = "The confirmed password does not match the password"
         isValid = false;
    }    


    if(isValid){
      

        var _jsonpost = {                       
                          "password":this.state.password,
                          "email":this.state.email
        };
          PostData('change_password',_jsonpost).then((result) => {
            
            let responseJson = result.results;

            

            if(!responseJson){   
    
              _tmp_error["error_insert"] = "error_insert"
              _message["error_insert"] = "Sorry, user already registered"
               isValid = false;
              this.setState({
                    error_form:_tmp_error,
                    error_message:_message,
                  })
              
            } else {              
              this.props.history.push("/login");
            }
            
           })
          .catch((err) => {
              _tmp_error["error_insert"] = "error_insert" 
              _message["error_insert"] = "Sorry, user already registered"             
              this.setState({
                    error_form:_tmp_error,
                    error_message:_message,
                  })          
            })

    } else {
              this.setState({
                    error_form:_tmp_error,
                    error_message:_message,
                  }) 
    }
  }

 onChange(e){
   var _tmp_array =  this.state.error_form;
   delete _tmp_array[e.target.name];  
   this.setState({
          [e.target.name]:e.target.value,
          error_form : _tmp_array 
        });
  }

  onClickSelect(e,action){ 
    delete this.state.error_form[action.name];
    this.setState({[action.name]:e.value});    
  }


  componentDidMount() {      
    this.getMailTmp(this.state.token); 
  }
 

  render() {
    const { t } = this.props;
    var {error_message} = this.state;
          var LabelStatus = ({ type , error , message}) => {
            
            switch(type) {

              case "password":
                  if (type===error[type]){
                      return (<React.Fragment><small className="text red">{t(message[type])}</small></React.Fragment>);
                  } else {
                       return (<React.Fragment/>);
                  } 
                break;
              case "repassword":
                  if (type===error[type]){
                      return (<React.Fragment><small className="text red">{t(message[type])}</small></React.Fragment>);
                  } else {
                       return (<React.Fragment/>);
                  }
                break;
              case "error_insert":
                  if (type===error[type]){
                      return (<React.Fragment><small className="text red">{t(message[type])}</small></React.Fragment>);
                  } else {
                       return (<React.Fragment/>);
                  }
                break;                                                                                                                                                                                                                                
            }   
        };    


    return (
  <div className="wrapper is-guest-user centered-wrap">

      <section className="slogan">
        <div className="logo"></div>
        <div className="title">
          <div className="title-1">
          {t('MEET YOUR FRIENDS')}
          </div>
          <div className="title-2">
            &amp;{t('PLAY PRIVATE POKER LIVE')}
          </div>
        </div>
		</section>

      <div className="app flex-row align-items-center">
        <Container>
          <div className="row justify-content-center my-4 card-box no-gutters page-register-step-2">
              <div className="col-sm-10 col-lg-7">
                  <div className="card-box-header text-center">{t("Change Password")}</div>
                  <div className="card-box-body py-4">
                      <div className="title text-center mb-4 h4">{t("Introduce new password for email account")} {this.state.email }</div>

                       
                        <div className="row px-4">
                            <div className="col p-1">{t("Password")}</div>
                            <div className="col p-1"><input name="password" onChange={this.onChange} type="password" className={this.state.error_form["password"] ? "w-100 bg-danger" : "w-100" } /><LabelStatus type="password" error={this.state.error_form} message={t({error_message})} /></div>
                        </div>
                        <div className="row px-4">
                            <div className="col p-1">{t("Confirm Password")}</div>
                            <div className="col p-1"><input name="repassword" onChange={this.onChange} type="password" className={this.state.error_form["repassword"] ? "w-100 bg-danger" : "w-100" } /><LabelStatus type="repassword" error={this.state.error_form} message={t({error_message})} /></div>
                        </div>
                        
                        <div className="row px-4 text-right">
                            <div className="col p-1 pt-4"><a onClick={this.signup} className="button green">{t("Save")}</a></div>
                        </div>
                  </div>
              </div>
          </div>        
        </Container>
      </div>
      <Footer home={false} register={true}/>
    </div>


 
    );
  }
}

export default withTranslation()(Change_Password);
