const config = {

    //API_URL: 'http://localhost:3010/',
    API_URL: 'https://api.pokerpeek.com/',
    //TABLE_URL:'http://localhost/',
    TABLE_URL:'https://table.pokerpeek.com/',
    //TOURNAMENT_URL:'http://localhost:8080/',
    TOURNAMENT_URL:'https://tournament.pokerpeek.com/',
    WEB_URL:'https://www.pokerpeek.com/',
    //WEB_URL:'http://localhost:9001/',
    PAYMENT_API_URL: 'https://gpaytest.pokerpeek.com',//'http://localhost',
        
    Head_config: {
          headers: {'Content-Type': 'application/x-www-form-urlencoded'}
          },
    API_GOOGLE_KEY : 'AIzaSyCmVpzlWlcSxxf2pEW_eUj7OpkQf8yO9M4',
    SOCKETIO_URL : 'https://tournament.pokerpeek.com:15600',
    //SOCKETIO_URL: 'http://localhost:15600',
    languages: ["en", "de", "es", "nl", "pt", "it", "cat", "fr"]
};
export default config;
