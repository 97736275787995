import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import { Container, Input } from 'reactstrap';
import { withTranslation } from 'react-i18next';
//import {PostData} from '../../../services/PostData';


import config from '../../../config';


class Cookies extends Component {

  constructor(props){
    super(props);   
    this.state = {
      showCookie:true,

    };
    this.acceptCookie = this.acceptCookie.bind(this);
    this.setupCookie = this.setupCookie.bind(this);
  }

  acceptCookie(){
       var _jsonurl = {
                status:true,
                conf:""
                } 
    localStorage.setItem('Cookies',JSON.stringify(_jsonurl));                    
    this.setState({showCookie:false});

  }

  setupCookie(){
    
  }

  onChange(event)
  {            

         this.setState({[event.target.name]:event.target.value});

  }


  componentDidMount() {        
  
           
       if(localStorage.getItem("Cookies")){
          var datalogin = JSON.parse(localStorage.getItem("Cookies"));
          if (datalogin.status){
            this.setState({showCookie:false});
          }
       }          
   }


  render() {
    const { t } = this.props;
    var acceptCookie = this.acceptCookie.bind(this);
    var setupCookie = this.setupCookie.bind(this);
    var {showCookie} = this.state;

    return (
      <React.Fragment>
      {showCookie &&
        <div className="cookies row no-gutters">
          <div className="col">
            {t("We use our own cookies and those of third parties to improve navigation, analyse our services, personalize the content and analyse the traffic.")}
            <br />
            {t("You can find more information in our")} <a href={config.WEB_URL+'legal/cookies.html'}>{t("Cookies Policy")}</a>.
          </div>
          <div className="col-12 col-md-auto mt-3 mt-md-0 text-center">
            <a onClick={ () => acceptCookie()} className="cursor-pointer button orange ml-md-3">{t("Accept")}</a>
            <a href={config.WEB_URL+'legal/cookies.html'} className="cursor-pointer button orange ml-3">{t("Setup")}</a>
          </div>
        </div>
      } 
      </React.Fragment>        
    );
  }
}


export default withTranslation()(Cookies);
