import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import {PostData} from '../../../services/PostData';
import {Fetch} from '../../../services/Fetch';
import { Container } from 'reactstrap';
import Moment from 'react-moment';

import configuration from '../../../config';
import {appendScriptHead} from './Components/appendScriptHead';
import {appendScriptBody} from './Components/appendScriptBody';
import Footer from '../Footer/Footer';
import { withTranslation } from 'react-i18next';

 var player_id ;          
 var player_token ;

class RegisterStep3 extends Component {

  constructor(props){
    super(props);
   
    this.state = {
        token:this.props.match.params.token || '',
        email:'',
        redirectToLogin:false,
        urltoredirect:'/private/clubs',
        terms:false,
        subscriptions:[],
        subscriptions_promos:[],
        loadSubscription:false,
        promo_code:'',
        showError:false,
        showError_promo_code:false,
        showLoadedePromo:false,
        showPromoPage:false,
        showPaymentPage:false,
        showGrace:true,
    };

    this.getMailTmp = this.getMailTmp.bind(this);    
    this.onChange = this.onChange.bind(this);
    this.getSubscriptions = this.getSubscriptions.bind(this);
    this.labelPromos = this.labelPromos.bind(this);
    this.applyPromoCode = this.applyPromoCode.bind(this);
    this.TryIt = this.TryIt.bind(this);
    this.accessToGame = this.accessToGame.bind(this);

  }
 
  accessToGame(){
      this.props.history.push(this.state.urltoredirect);
      
  }


  async applyPromoCode(promo_code){
    var _json ={};
         var  _res = await Fetch('get','subscription/all_promo/'+promo_code ,_json); 
         
        if (_res.results.length > 0){
            this.setState({subscriptions_promos:_res.results,
                            showLoadedePromo:true,
                          })
        } else {
            this.setState({
                            showError_promo_code:true,
                            subscriptions_promos:[]
          })  
        }     
  }

  async TryIt(index,subscripcions,index_promo,subscriptions_promo){    
    var _json = { "player_id":player_id,
    }
    if (subscriptions_promo){
      _json.subscription_model_id = subscripcions[index].id;
      _json.subscription_model_promo_id = subscriptions_promo[index_promo].id;
      _json.concept_clubs_founded = subscriptions_promo[index_promo].concept_clubs_founded;
      _json.concept_clubs_member = subscriptions_promo[index_promo].concept_clubs_member;
      _json.concept_games_allowed = subscriptions_promo[index_promo].concept_games_allowed;
      _json.concept_gallery_ar_total = subscriptions_promo[index_promo].concept_gallery_ar_total;
      _json.price = subscriptions_promo[index_promo].price;
    } else {
      _json.subscription_model_id = subscripcions[index].id;      
      _json.concept_clubs_founded = subscripcions[index].concept_clubs_founded;
      _json.concept_clubs_member = subscripcions[index].concept_clubs_member;
      _json.concept_games_allowed = subscripcions[index].concept_games_allowed;
      _json.concept_gallery_ar_total = subscripcions[index].concept_gallery_ar_total;
      _json.price = subscripcions[index].price;     
    }



    var  _result = await Fetch('post','subscription/try' ,_json); 
    if (_result){
        this.setState({showPaymentPage:true,
                        showPromoPage:false,
        });
        //this.props.history.push(this.state.urltoredirect);
    } else {
            this.setState({showError:true,
                            error : "You must accept terms and conditions",
          })  
    }
  }

  async getSubscriptions(){
    var _json ={};
         var  _res = await Fetch('get','subscription/all' ,_json); 
        if (_res.results){
            this.setState({subscriptions:_res.results,
                           loadSubscription:true,
                          })
        } else {
            this.setState({showError:true,
                            error : "Error in registering step 2, please contact the administrator",
          })  
        }   
  }



  getMailTmp(token){

            var _dateNow = new Date();

            PostData('getMailByTokenTmp',{"token":token}).then((result) => {
              
             let responseJson = result.results;
             if(responseJson.email)
             {         
              var min = Math.floor(( _dateNow.getTime() - new Date(responseJson.created_at).getTime()) / (1000 * 60 * 60));
              if (min > 10){
                this.props.history.push("/thankyou/The link has expired");
              }  else {
                if (responseJson.url_redirect){
                  if (responseJson.url_redirect.length>0){
                      var _url_redirect = responseJson.url_redirect.split("?")
                      if (_url_redirect.length > 1){
                        var _jsonurl = {
                                  page:_url_redirect[0],
                                  query:"?"+_url_redirect[1]
                                  } 
                        sessionStorage.setItem('UrlData',JSON.stringify(_jsonurl));
                        this.setState({urltoredirect:responseJson.url_redirect});
                      }

                  }
                }                
                this.setState({email:responseJson.email})
              }              

                                   
             } else {
                this.props.history.push("/thankyou/The link has expired");
             }
             
            });    
  }

 onChange(e){
   this.setState({[e.target.name]:e.target.value});
   this.setState({showLoadedePromo:false,
                  showError_promo_code:false,
                  });

  }

  componentDidMount() {     
    this.getMailTmp(this.state.token); 
    //this.getSubscriptions();

  }
  componentWillMount() {

         if(localStorage.getItem("userData")){ 
          var datalogin = JSON.parse(localStorage.getItem("userData"));
          player_id = datalogin.id;          
          player_token = datalogin.token;
         }    
         else{
          this.setState({redirectToReferrer: true});
         }
  }

  labelPromos(subscriptions,subscriptions_promos){
    const { t } = this.props
    var TryIt = this.TryIt;
    if (subscriptions_promos.length > 0){

        return (<React.Fragment>
                    <div className="row subscription-model px-4 first">
                        <div className="col"><div></div></div>
                        <div className="col"><div>{subscriptions[0].title}</div></div>
                        <div className="col"><div>{subscriptions[1].title}</div></div>
                        <div className="col"><div>{subscriptions[2].title}<div className="promo-label"><span>{t("best")}</span><span>{t("choice")}</span></div></div></div>
                        <div className="col"><div>{subscriptions[3].title}</div></div>
                    </div>
                    <div className="row subscription-model px-4">
                        <div className="col"><div>{t("Active clubs founded by me")}</div></div>
                        <div className="col"><div>{subscriptions[0].concept_clubs_founded} {t("max")}.</div></div>
                        <div className="col"><div>{subscriptions[1].concept_clubs_founded} {t("max")}.</div></div>
                        <div className="col"><div>{subscriptions[2].concept_clubs_founded} {t("max")}.</div></div>
                        <div className="col"><div>{subscriptions[3].concept_clubs_founded > 999 ? `${t("Unlimited")}`:subscriptions[3].concept_clubs_founded + ` ${t("max")}`}</div></div>
                    </div>
                    <div className="row subscription-model px-4">
                        <div className="col"><div>{t("Clubs where I'm a member")}</div></div>
                        <div className="col"><div>{subscriptions[0].concept_clubs_member} {t("max")}.</div></div>
                        <div className="col"><div>{subscriptions[1].concept_clubs_member} {t("max")}.</div></div>
                        <div className="col"><div>{subscriptions[2].concept_clubs_member} {t("max")}.</div></div>
                        <div className="col"><div>{subscriptions[3].concept_clubs_member > 999 ? `${t("Unlimited")}`:subscriptions[3].concept_clubs_member + ` ${t("max")}`}</div></div>
                    </div>
                    <div className="row subscription-model px-4">
                        <div className="col"><div>{t("Games I'm allowed to play monthly")}</div></div>
                        <div className="col"><div>{subscriptions[0].concept_games_allowed} {t("max")}.</div></div>
                        <div className="col"><div>{subscriptions[1].concept_games_allowed} {t("max")}.</div></div>
                        <div className="col"><div>{subscriptions[2].concept_games_allowed} {t("max")}.</div></div>
                        <div className="col"><div>{subscriptions[3].concept_games_allowed > 999 ? `${t("Unlimited")}`:subscriptions[3].concept_games_allowed + ` ${t("max")}`}</div></div>
                    </div>
                    <div className="row subscription-model px-4">

                        <div className="col"><div>{t("Gallery of 3D AR filters")}</div></div>
                        <div className="col"><div>{subscriptions[0].concept_gallery_ar_total}</div></div>
                        <div className="col"><div>{subscriptions[1].concept_gallery_ar_total}</div></div>
                        <div className="col"><div>{subscriptions[2].concept_gallery_ar_total}</div></div>
                        <div className="col"><div>{subscriptions[3].concept_gallery_ar_total > 999 ? "All(15)":subscriptions[3].concept_gallery_ar_total}</div></div>
                    </div>
                    <div className="row subscription-model px-4 last">
                        <div className="col"><div></div></div>
                        <div className="col"><div><span className="price">{subscriptions[0].price === 0 ? "Free":subscriptions[0].price +"€"}</span><span className="small-text"></span><span className="price"></span><span><a onClick= { () => TryIt(0,subscriptions,0,subscriptions_promos)} className="try-it-button">{t("Try it")}</a></span></div></div>
                        <div className="col"><div><span className="price strikethrough">{subscriptions[1].price}€/{t("month")}</span><span className="small-text">{t("Promo expires on")} <Moment format="MMMM Do">{subscriptions_promos[1].date_end}</Moment></span><span className="price">{subscriptions_promos[1].price}€/{t("month")}</span><span><a onClick= { () => TryIt(1,subscriptions,1,subscriptions_promos)} className="try-it-button">{t("Try it")}</a></span></div></div>
                        <div className="col"><div><span className="price strikethrough">{subscriptions[2].price}€/{t("month")}</span><span className="small-text">{t("Promo expires on")} <Moment format="MMMM Do">{subscriptions_promos[2].date_end}</Moment></span><span className="price">{subscriptions_promos[2].price}€/{t("month")}</span><span><a onClick= { () => TryIt(2,subscriptions,2,subscriptions_promos)} className="try-it-button">{t("Try it")}</a></span></div></div>
                        <div className="col"><div><span className="price strikethrough">{subscriptions[3].price}€/{t("month")}</span><span className="small-text">{t("Promo expires on")} <Moment format="MMMM Do">{subscriptions_promos[3].date_end}</Moment></span><span className="price">{subscriptions_promos[3].price}€/{t("month")}</span><span><a onClick= { () => TryIt(3,subscriptions,3,subscriptions_promos)} className="try-it-button">{t("Try it")}</a></span></div></div>
                    </div>

                    <div className="pr-3 mt-2 text-center">
                        <div>
                            <small>
                                {t("You can change or cancel your subscription whenever you want. The price you got with any promo will be kept during")} {subscriptions_promos[0].months_promo_profit} {t("months")}.
                            </small>
                        </div>
                    </div>                                                           
                </React.Fragment>
                );


    } else {

          return (<React.Fragment>
                    <div className="row subscription-model px-4 first">
                        <div className="col"><div></div></div>
                        <div className="col"><div>{subscriptions[0].title}</div></div>
                        <div className="col"><div>{subscriptions[1].title}</div></div>
                        <div className="col"><div>{subscriptions[2].title}<div className="promo-label"><span>{t("best")}</span><span>{t("choice")}</span></div></div></div>
                        <div className="col"><div>{subscriptions[3].title}</div></div>
                    </div>
                    <div className="row subscription-model px-4">
                        <div className="col"><div>{t("Active clubs founded by me")}</div></div>
                        <div className="col"><div>{subscriptions[0].concept_clubs_founded} {t("max")}.</div></div>
                        <div className="col"><div>{subscriptions[1].concept_clubs_founded} {t("max")}.</div></div>
                        <div className="col"><div>{subscriptions[2].concept_clubs_founded} {t("max")}.</div></div>
                        <div className="col"><div>{subscriptions[3].concept_clubs_founded > 999 ? `${t("Unlimited")}`:subscriptions[3].concept_clubs_founded + ` ${t("max")}`}</div></div>
                    </div>
                    <div className="row subscription-model px-4">
                        <div className="col"><div>{t("Clubs where I'm a member")}</div></div>
                        <div className="col"><div>{subscriptions[0].concept_clubs_member} {t("max")}.</div></div>
                        <div className="col"><div>{subscriptions[1].concept_clubs_member} {t("max")}.</div></div>
                        <div className="col"><div>{subscriptions[2].concept_clubs_member} {t("max")}.</div></div>
                        <div className="col"><div>{subscriptions[3].concept_clubs_member > 999 ? `${t("Unlimited")}`:subscriptions[3].concept_clubs_member + ` ${t("max")}`}</div></div>
                    </div>
                    <div className="row subscription-model px-4">
                        <div className="col"><div>{t("Games I'm allowed to play monthly")}</div></div>
                        <div className="col"><div>{subscriptions[0].concept_games_allowed} {t("max")}.</div></div>
                        <div className="col"><div>{subscriptions[1].concept_games_allowed} {t("max")}.</div></div>
                        <div className="col"><div>{subscriptions[2].concept_games_allowed} {t("max")}.</div></div>
                        <div className="col"><div>{subscriptions[3].concept_games_allowed > 999 ? `${t("Unlimited")}`:subscriptions[3].concept_games_allowed + ` ${t("max")}`}</div></div>
                    </div>
                    <div className="row subscription-model px-4">

                        <div className="col"><div>{t("Gallery of 3D AR filters")}</div></div>
                        <div className="col"><div>{subscriptions[0].concept_gallery_ar_total}</div></div>
                        <div className="col"><div>{subscriptions[1].concept_gallery_ar_total}</div></div>
                        <div className="col"><div>{subscriptions[2].concept_gallery_ar_total}</div></div>
                        <div className="col"><div>{subscriptions[3].concept_gallery_ar_total > 999 ? `${t("All")} (15)`:subscriptions[3].concept_gallery_ar_total}</div></div>
                    </div>
                    <div className="row subscription-model px-4 last">

                        <div className="col"><div></div></div>
                        <div className="col"><div><span className="price">{subscriptions[0].price === 0 ? "Free":subscriptions[0].price +"€"}</span><span><a onClick= { () => TryIt(0,subscriptions,null,null)} className="try-it-button">{t("Try it")}</a></span></div></div>
                        <div className="col"><div><span className="price">{subscriptions[1].price}€/{t("month")}</span><span><a onClick= { () => TryIt(1,subscriptions,null,null)}  className="try-it-button">{t("Try it")}</a></span></div></div>
                        <div className="col"><div><span className="price">{subscriptions[2].price}€/{t("month")}</span><span><a onClick= { () => TryIt(2,subscriptions,null,null)}  className="try-it-button">{t("Try it")}</a></span></div></div>
                        <div className="col"><div><span className="price">{subscriptions[3].price}€/{t("month")}</span><span><a onClick= { () => TryIt(3,subscriptions,null,null)} className="try-it-button">{t("Try it")}</a></span></div></div>

                    </div>                                                            
                </React.Fragment>
                );    
    }
  }





  render() {
    const { t } = this.props

    var {subscriptions,loadSubscription,subscriptions_promos,showError,showError_promo_code,showLoadedePromo,showPaymentPage,showPromoPage,showGrace} = this.state;
    var applyPromoCode = this.applyPromoCode.bind(this);
    
    this.labelPromos = this.labelPromos.bind(this);
    this.TryIt = this.TryIt.bind(this);
    var accessToGame = this.accessToGame;
    

    return (
    <div className="wrapper is-guest-user centered-wrap">

      <section className="slogan">
        <div className="logo"></div>
        <div className="title">
          <div className="title-1">
          {t("MEET YOUR FRIENDS")}
          </div>
          <div className="title-2">
            &amp;{t("PLAY PRIVATE POKER LIVE")}
          </div>
        </div>
			</section>

      {showPromoPage && 

          <div className="app flex-row align-items-center">
            <Container>
              <div className="row justify-content-center my-4 card-box no-gutters page-register-subscription">
                  <div className="col">
                      <div className="card-box-header text-center white-text-shadow">{t("CREATE ACCOUNT")}</div>
                      <div className="card-box-body py-4">
                          <div className="title text-left mb-4 h5 px-4">
                            <div className="row no-gutters">
                                <div className="col-auto align-self-center">{t("Choose the subscription model. Do you have a promo code?")}</div>
                                <div className="col-auto"><input type="text" onChange={this.onChange} id="promo_code" name="promo_code" value={this.state.promo_code} placeholder={t("Introduce promo code")} className="promo-code" /></div>
                                <div className="col-auto"><a href="#" onClick= { () => applyPromoCode(this.state.promo_code)} className="button orange ml-2">{t("Apply")}</a></div>
                                <div className="col-auto align-self-center ml-2">
                                 {showError_promo_code &&   
                                  <small className="text red">{t("Invalid or expired code")}</small>
                                }
                                 {showLoadedePromo &&
                                    <small className="text green">{t("Promotion loaded")}</small>
                                 }
                                </div>
                            </div>

                          </div>
                          {loadSubscription && this.labelPromos(subscriptions,subscriptions_promos)}
                          {showError && <div><small className="text red">{this.state.error}</small></div> }
                      </div>
                  </div>
              </div>
            </Container>
          </div>
        }
        {showGrace && 
                  <div className="app flex-row align-items-center">
                      <Container>
                          <div className="row justify-content-center my-4 card-box no-gutters page-check-email">
                              <div className="col-sm-10 col-lg-7">
                              <div className="card-box-header text-center white-text-shadow">{t("CREATE ACCOUNT")}</div>
                                <div className="card-box-body py-4">
                                      <div className="title text-center h2 py-4 font-bold">{t("Thanks for creating your account")}</div>
                                      <div className="row">
                                          <div className="col-10 offset-1 text-muted font-medium">
                      {t("It is an honor to have you here")} ;)<br />{t("Congratulations! You have got a 30 days trial access and 100,000 playing money for free.")}
                                          <br />{t("Have fun!")}
                                          </div>                                           
                                      </div>
                                      <div className="row justify-content-center py-4">
                                          <div className="col-auto">
                      <span><a onClick={accessToGame} className="try-it-button button orange">{t("Games access")}</a></span>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </Container>
                    </div>
        }        
        {showPaymentPage && 
                  <div className="app flex-row align-items-center">
                      <Container>
                          <div className="row justify-content-center my-4 card-box no-gutters page-check-email">
                              <div className="col-sm-10 col-lg-7">
                              <div className="card-box-header text-center white-text-shadow">{t("CREATE ACCOUNT")}</div>
                                <div className="card-box-body py-4">
                                      <div className="title text-center h2 py-4">{t("Set up payment method")}</div>
                                      <div className="row">
                                          <div className="col-10 offset-1 text-muted">
                                              {t("Enter your credit card data to finalize the subscription process")}. 
                                              {t("This data will be sent to Aryen, our international payments partner, who will manage the process compliant with all security regulations")}.
                                          </div> 
                                          <br /><br /><br />                                         
                                          <div className="col-10 offset-1 text-muted">
                                              {t("We are testing the beta version of the platform, you can continue using the platform for free until it's really ready")}.
                                          </div>                                          
                                      </div>

                                      <div className="row justify-content-center py-4">
                                          <div className="col-auto">
                      <span><a onClick={accessToGame} className="try-it-button button orange">{t("Games access")}</a></span>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </Container>
                    </div>
        }  
      <Footer home={false} register={true}/> 
    </div>


 

    );
  }
}

export default withTranslation()(RegisterStep3);
