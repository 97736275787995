export const appendScriptBody = (appendScriptBody,type) => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = appendScriptBody;
    if (type){
    	script.async = true;
    } else {
    	script.async = false
    }
    
    document.body.appendChild(script);
}