import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import {PostData} from '../../../services/PostData';
import {Fetch} from '../../../services/Fetch';
import { Container } from 'reactstrap';
import Select from 'react-select';
import moment from 'moment';
import config from '../../../config';
import Footer from '../Footer/Footer';
import { withTranslation } from 'react-i18next';




function isDate18orMoreYearsOld(day, month, year) {
    return new Date(parseInt(year)+18, parseInt(month)-1, parseInt(day)) <= new Date();
}

const colorStyles = {
  control: (styles, { data, isDisabled, isFocused, isSelected, selectProps: { value } }) => {
    const choseValue = (value.value == '') ? false : true
    return {
      ...styles,
      backgroundColor: '#048ca7ee',
      opacity: isFocused ? 1 : isSelected ? 1 : choseValue ? 1 : 0.5
    }
  },
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    
    return {
      ...styles,
      backgroundColor: isDisabled? '#ffffff': '#048ca7ee',
      color: '#FFF',
      cursor: isDisabled ? 'not-allowed' : 'default',
    };
  },
  placeholder: defaultStyles => {
    return {
      ...defaultStyles,
      color: '#FFF'
    };
  }
}

class Register extends Component {

  constructor(props){
    super(props);
    const {t} = this.props
   
    this.state = {
     name:'',
     surname:'',
     nickname:'', 
     username: '',
     password: '',
     repassword:'',
     sex:'',
     option_sex:[
                  { value : '',label: t('Choose your gender')},
                  { value : 'male',label: t('male')},
                  { value : 'female',label: t('female')},
                  { value : 'other',label: t('other')},
                 ],
     age:'',
     email: '',
     iso_country: '',
     language_iso_code:'',
     optionCountry:[{label:t('Choose your Country'),value:''}],             
     optionLanguage:[{label:t('Choose your Language'),value:''}],
     bb_day:'',
     bb_day_day:'',
     bb_day_year:'',
     bb_day_mounth:'',
     token:this.props.match.params.token || '',
     error_form:[],
     error_message:[],
     terms:false,
    };

    this.signup = this.signup.bind(this);
    this.onChange = this.onChange.bind(this);
    this.getMailTmp = this.getMailTmp.bind(this);
    this.onClickSelect = this.onClickSelect.bind(this);
    this.loadCountrys = this.loadCountrys.bind(this);
    this.loadLanguage = this.loadLanguage.bind(this);

  }



  async loadCountrys()
  {
    const {t} = this.props
     var _json = {}       
     var  _res = await Fetch('get','iso/countrys',_json);
     if (_res.results){
       var defaultValue = { value: '', label: t('Choose your Country')};
          let option = _res.results.map(countrys => 
              ({ value: countrys.country_iso_code, label: countrys.title })
          ); 
          option = [defaultValue].concat(option);
        this.setState({ optionCountry : option}); 
     }

  }

  async loadLanguage()
  {
    const {t} = this.props
     var _json = {}       
     var  _res = await Fetch('get','iso/language',_json);     
     if (_res.results){
       var defaultValue = { value: '', label: t('Choose your Language')};
          let option = _res.results.map(language => 
              ({ value: language.language_iso_code, label: t(language.title) })
          ); 
          option = [defaultValue].concat(option);
        this.setState({ optionLanguage : option}); 
     }

  }

 
  getMailTmp(token){

            var _dateNow = new Date();

            PostData('getMailByTokenTmp',{"token":token}).then((result) => {
              
             let responseJson = result.results;
             if(responseJson.email)
             {         
              var min = Math.floor(( _dateNow.getTime() - new Date(responseJson.created_at).getTime()) / (1000 * 60 * 60));
              if (min > 10){
                this.props.history.push("/thankyou/The link has expired");
              }  else {
                if (responseJson.url_redirect){
                  if (responseJson.url_redirect.length>0){
                      var _url_redirect = responseJson.url_redirect.split("?")
                      if (_url_redirect.length > 1){
                        var _jsonurl = {
                                  page:_url_redirect[0],
                                  query:"?"+_url_redirect[1]
                                  } 
                        sessionStorage.setItem('UrlData',JSON.stringify(_jsonurl));
                      }
                  }
                }
                
                this.setState({email:responseJson.email})
              }              

                                   
             } else {
                this.props.history.push("/thankyou/The link has expired");
             }
             
            });    
  }

  signup() {
    console.log('now trying to sign up')

    var isValid = true;
    var _tmp_error =[];
    var _message = [];
    var _dateNow = new Date();


    if (this.state.name){
        
    } else {
        _tmp_error["name"] = "name"
        _message["name"] = "Name must contain letters"
        isValid = false;
    }
    // if (this.state.surname){
        
    // } else {
    //     _tmp_error["surname"] = "surname"
    //     _message["surname"] = "SurName must contain letters"
    //     isValid = false;
    // }
    if (this.state.username){
        
    } else {
        _tmp_error["username"] = "username"
        _message["username"] = "Username must contain letters"
        isValid = false;
    } 
    if (this.state.password){
      if (this.state.password.length < 6)
      {
        _tmp_error["password"] = "password"
        _message["password"] = "Password must contain at least 6 characters"
        isValid = false;       
      }
        
    } else {
        _tmp_error["password"] = "password"
        _message["password"] = "Password must contain letters"
        isValid = false;
    } 
    if (this.state.repassword){
        
    } else {
        _tmp_error["repassword"] = "repassword"
        _message["repassword"] = "Please confirm your password"
        isValid = false;
    }
    if (this.state.sex){
        
    } else {
        _tmp_error["sex"] = "sex"
        _message["sex"] = "Please choose gender"
        isValid = false;
    }  
    if (this.state.iso_country){
        
    } else {
        _tmp_error["iso_country"] = "iso_country"
        _message["iso_country"] = "Please choose residence country"
        isValid = false;
    }  
    if ((this.state.bb_day_day) && (this.state.bb_day_mounth) && (this.state.bb_day_year)){
        var _temp_dateToValidate = this.state.bb_day_day+ '/' + this.state.bb_day_mounth + '/' + this.state.bb_day_year;

        var _isValid_fecha = moment(_temp_dateToValidate, 'DD/MM/YYYY',true).isValid();

        if (_isValid_fecha) {
            var temp_bb_day_day = _temp_dateToValidate.split("/")[0];
            var temp_bb_day_mounth = _temp_dateToValidate.split("/")[1]; 
            var temp_bb_day_year = _temp_dateToValidate.split("/")[2];
            if  (temp_bb_day_day.length===1) { temp_bb_day_day = "0" + temp_bb_day_day}
            if  (temp_bb_day_mounth.length===1) { temp_bb_day_mounth = "0" + temp_bb_day_mounth}
            var tmp_bb_day = temp_bb_day_year + "-" + temp_bb_day_mounth + "-" +  temp_bb_day_day ;            
            var age = Math.floor(( _dateNow.getTime() - new Date(tmp_bb_day).getTime()) / (1000 * 60 * 60 * 24 *365));
            //if (isDate18orMoreYearsOld(temp_bb_day_day,temp_bb_day_mounth,temp_bb_day_year)){
              
            //} else {
             // _tmp_error["bb_day"] = "bb_day"
             // _message["bb_day"] = "You must be 18"
             // isValid = false;
            //}
        } else {
          _tmp_error["bb_day"] = "bb_day"
          _message["bb_day"] = "Wrong format date (dd mm yyyy)"
          isValid = false;
        }
    } else {
        _tmp_error["bb_day"] = "bb_day"
        _message["bb_day"] = "Please choose birthday"
        isValid = false;
    }  
    if (this.state.language_iso_code){
      
    } else {
        _tmp_error["language_iso_code"] = "language_iso_code"
        _message["language_iso_code"] = "Please choose default language"
        isValid = false;
    } 

    if ((this.state.password)&&(this.state.repassword)&&(this.state.password===this.state.repassword)) {
        
    } else 
    {
        _tmp_error["repassword"] = "repassword";
        _message["repassword"] = "The confirmed password does not match the password"
         isValid = false;
    }    

    if (!this.state.terms){
        _tmp_error["terms"] = "terms";
        _message["terms"] = "You must accept terms and conditions"
         isValid = false;      
    }


    if(isValid){
      console.log('valid data')

        var _jsonpost = { "name":this.state.name,
                          "surname":"",
                          "iso_country": this.state.iso_country,
                          "language_iso_code": this.state.language_iso_code,
                          "bb_day": tmp_bb_day,
                          "age":age,
                          "sex": this.state.sex,
                          "nickname": this.state.username,
                          "username":this.state.username,
                          "password":this.state.password,
                          "status":1,
                          "email":this.state.email


        };
          PostData('registerform',_jsonpost).then((result) => {
            
            let responseJson = result;

            if(responseJson.hasOwnProperty("errors")){   
    
                if (responseJson.errors[0]==="18"){
                   _tmp_error["bb_day"] = "bb_day"
                   _message["bb_day"] = "You must be 18"
                    this.setState({
                          error_form:_tmp_error,
                          error_message:_message,
                        })
                } else {
                 _tmp_error["error_insert"] = "error_insert"
                  _message["error_insert"] = "Sorry, user already registered"
                   isValid = false;
                  this.setState({
                        error_form:_tmp_error,
                        error_message:_message,
                      })
                }


              
            } else {
              localStorage.setItem('userData', JSON.stringify(responseJson));
              switch (this.state.language_iso_code) {
                case "en_GB": {
                  localStorage.setItem('language', "en")
                  break
                }
                case "de_DE": {
                  localStorage.setItem('language', "de")
                  break
                }

                case "es_ES": {
                  localStorage.setItem('language', "es")
                  break
                }

                case "nl_NL": {
                  localStorage.setItem('language', "nl")
                  break
                }

                default: {

                }
              }
              this.props.history.push("/registerstep2/" + this.state.token);
              window.location.reload();
            }
            
           })
          .catch((err) => {
              _tmp_error["error_insert"] = "error_insert" 
              _message["error_insert"] = "Sorry, user already registered"             
              this.setState({
                    error_form:_tmp_error,
                    error_message:_message,
                  })          
            })

    } else {
              this.setState({
                    error_form:_tmp_error,
                    error_message:_message,
                  }) 
    }
  }

 onChange(e){
  var _tmp_array =  this.state.error_form;
  delete _tmp_array[e.target.name];  
      if (e.target.name==='terms'){
         this.setState({terms:!this.state.terms});
      } else {
         this.setState({
                [e.target.name]:e.target.value,
                error_form : _tmp_array 
              });
      }

  }

  onClickSelect(e,action){ 
    delete this.state.error_form[action.name];
    this.setState({[action.name]:e.value});    
  }


  componentDidMount() {   
    
    this.getMailTmp(this.state.token); 
    this.loadCountrys(); 
    this.loadLanguage();

  }
 

  render() {
    const { t } = this.props
          var {bb_day,showError,bb_day_day,bb_day_mounth,bb_day_year} = this.state;

          var LabelStatus = ({ type , error , message}) => {
            
            switch(type) {
              case "name":
                  if (type===error[type]){
                      return (<React.Fragment><small className="text red">{t(message[type])}</small></React.Fragment>);
                  } else {
                       return (<React.Fragment/>);
                  }                
                break;
              case "surname":
                  if (type===error[type]){
                      return (<React.Fragment><small className="text red">{t(message[type])}</small></React.Fragment>);
                  } else {
                       return (<React.Fragment/>);
                  } 
                break;
              case "username":
                  if (type===error[type]){
                      return (<React.Fragment><small className="text red">{t(message[type])}</small></React.Fragment>);
                  } else {
                       return (<React.Fragment/>);
                  } 
                break;
              case "password":
                  if (type===error[type]){
                      return (<React.Fragment><small className="text red">{t(message[type])}</small></React.Fragment>);
                  } else {
                       return (<React.Fragment/>);
                  } 
                break;
              case "repassword":
                  if (type===error[type]){
                      return (<React.Fragment><small className="text red">{t(message[type])}</small></React.Fragment>);
                  } else {
                       return (<React.Fragment/>);
                  }
                break;
              case "bb_day":
                  if (type===error[type]){
                      return (<React.Fragment><small className="text red">{t(message[type])}</small></React.Fragment>);
                  } else {
                       return (<React.Fragment/>);
                  }
                break;
              case "iso_country":
                  if (type===error[type]){
                      return (<React.Fragment><small className="text red">{t(message[type])}</small></React.Fragment>);
                  } else {
                       return (<React.Fragment/>);
                  }
                break;
              case "language_iso_code":
                  if (type===error[type]){
                      return (<React.Fragment><small className="text red">{t(message[type])}</small></React.Fragment>);
                  } else {
                       return (<React.Fragment/>);
                  }
                break; 
              case "sex":
                  if (type===error[type]){
                      return (<React.Fragment><small className="text red">{t(message[type])}</small></React.Fragment>);
                  } else {
                       return (<React.Fragment/>);
                  }
                break;
              case "error_insert":
                  if (type===error[type]){
                      return (<React.Fragment><small className="text red">{t(message[type])}</small></React.Fragment>);
                  } else {
                       return (<React.Fragment/>);
                  }
                break;
              case "terms":
                  if (type===error[type]){
                      return (<React.Fragment><small className="text red">{t(message[type])}</small></React.Fragment>);
                  } else {
                       return (<React.Fragment/>);
                  }
                break;                                                                                                                                                                                                                                                
            }   
        };    


    return (
  <div className="wrapper is-guest-user centered-wrap">

    <section className="slogan">
      <div className="logo"></div>
        <div className="title">
          <div className="title-1">
            {t("MEET YOUR FRIENDS")}
          </div>
          <div className="title-2">
            &amp;{t("PLAY PRIVATE POKER LIVE")}
          </div>
        </div>
		</section>

      <div className="app flex-row align-items-center">
        <Container>
          <div className="row justify-content-center my-4 card-box no-gutters page-register-step-2">
              <div className="col-sm-10 register col-lg-8">
                  <div className="card-box-header text-center white-text-shadow">{t("CREATE ACCOUNT")}</div>
                  <div className="card-box-body py-4">
                      <div className="title text-center mb-4 h4 font-bold">{t("Set up your account by completing the form below")}</div>

                        <div className="row px-4 font-medium">
                            <div className="col p-1">{t("Name")}</div>
                            <div className="col p-1"><input name="name" onChange={this.onChange} type="text" placeholder={t("Name")} className={this.state.error_form["name"] ? "w-100 bg-danger" : "w-100" } /><LabelStatus type="name" error={this.state.error_form} message={this.state.error_message}/></div>
                        </div>
                        {/* <div className="row px-4 font-medium">
                            <div className="col p-1">Surname</div>
                            <div className="col p-1"><input name="surname" onChange={this.onChange} type="text" placeholder="Surname" className={this.state.error_form["surname"] ? "w-100 bg-danger" : "w-100" } /><LabelStatus type="surname" error={this.state.error_form} message={this.state.error_message} /></div>
                        </div> */}

                        <div className="row px-4 font-medium">
                            <div className="col p-1">{t("Username")}</div>
                            <div className="col p-1"><input name="username" onChange={this.onChange} type="text" placeholder={t("Username")} className={this.state.error_form["username"] ? "w-100 bg-danger" : "w-100" } /><LabelStatus type="username" error={this.state.error_form} message={this.state.error_message} /></div>
                        </div>
                        <div className="row px-4 font-medium">
                            <div className="col p-1">{t("Password")}</div>
                    <div className="col p-1"><input name="password" onChange={this.onChange} type="password" placeholder={t("Password")} className={this.state.error_form["password"] ? "w-100 bg-danger" : "w-100"} /><LabelStatus type="password" error={this.state.error_form} message={this.state.error_message} /></div>
                        </div>
                        <div className="row px-4 font-medium">
                            <div className="col p-1">{t("Confirm Password")}</div>
                            <div className="col p-1"><input name="repassword" onChange={this.onChange} type="password" placeholder={t("Confirm Password")} className={this.state.error_form["repassword"] ? "w-100 bg-danger" : "w-100" } /><LabelStatus type="repassword" error={this.state.error_form} message={this.state.error_message} /></div>
                        </div>
                        <div className="row px-4 font-medium">
                            <div className="col p-1">{t("Gender (internal use only)")}</div>
                            <div className="col p-1">
                            <Select className="select w-100" classNamePrefix="select" name="sex" components={{ IndicatorSeparator: () => null }} value={this.state.option_sex.find(o => o.value === this.state.sex)} onChange={this.onClickSelect} options={this.state.option_sex} styles={colorStyles}/><LabelStatus type="sex" error={this.state.error_form} message={this.state.error_message} />
                            </div>
                        </div>
                        <div className="row px-4 font-medium">
                            <div className="col p-1">{t("Residence country")}</div>
                            <div className="col p-1">
                              <Select className="select w-100" classNamePrefix="select" name="iso_country" components={{ IndicatorSeparator: () => null }} value={this.state.optionCountry.find(o => o.value === this.state.iso_country)} onChange={this.onClickSelect} options={this.state.optionCountry} styles={colorStyles} /><LabelStatus type="iso_country" error={this.state.error_form}  message={this.state.error_message}/>
                            </div>
                        </div>
                        <div className="row px-4 font-medium">
                            <div className="col p-1">{t("Birthday (dd/mm/yyyy)")}</div>
                            <div className="col p-1 no-gutters">
                                {/*<input name="bb_day"  value ={bb_day} onChange={this.onChange} type="text" placeholder="dd/mm/yyyy" className={this.state.error_form["bb_day"] ? "w-100 bg-danger" : "w-100" } /><LabelStatus type="bb_day" error={this.state.error_form} message={this.state.error_message} />*/}
                                <div className="row">
                                    <div className="col-3 pr-1">
                                <input name="bb_day_day"  value ={this.state.bb_day_day} onChange={this.onChange} type="text" placeholder="dd" className={this.state.error_form["bb_day"] ? "text-center bg-danger w-100" : "text-center w-100" } />
                                    </div>
                                <div className="col-3 px-1">
                                <input name="bb_day_mounth"  value ={this.state.bb_day_mounth} onChange={this.onChange} type="text" placeholder="mm" className={this.state.error_form["bb_day"] ? "text-center bg-danger w-100" : "text-center w-100" } />
                                     </div>
                                <div className="col-6 pl-1">
                                 <input name="bb_day_year"  value ={this.state.bb_day_year} onChange={this.onChange} type="text" placeholder="yyyy" className={this.state.error_form["bb_day"] ? "text-center bg-danger w-100" : "text-center w-100" } />
                                </div>
                                <LabelStatus type="bb_day" error={this.state.error_form} message={this.state.error_message} />
                      </div>

                            </div>
                        </div>
                         <div className="row px-4 font-medium">
                    <div className="col p-1">{t('Default Language')}</div>
                            <div className="col p-1">
                              <Select className="select w-100" classNamePrefix="select" components={{ IndicatorSeparator: () => null }} name="language_iso_code" value={this.state.optionLanguage.find(o => o.value === this.state.language_iso_code)} onChange={this.onClickSelect} options={this.state.optionLanguage} styles={colorStyles} />
                              <LabelStatus type="language_iso_code" error={this.state.error_form}  message={this.state.error_message}/>
                              <LabelStatus type="error_insert" error={this.state.error_form}  message={this.state.error_message}/>
                            </div>
                        </div>
                        <div className="row px-4 font-cond-book">
                        <div className="col p-1"></div>
                            <div className="col p-2">
                                <small>
                                    <input checked={this.state.terms} onChange={this.onChange} type="checkbox" id="terms" name="terms"/>
                                    {t("I accept")} <a href={config.WEB_URL + 'legal/terms_and_conditions.html'} target="_blank">{t("terms and conditions")}</a>
                                </small>
                            </div>
                            <LabelStatus type="terms" error={this.state.error_form}  message={this.state.error_message}/>
                        </div>                        
                        <div className="row px-4 text-right">
                            <div className="col p-1 pt-4"><a onClick={this.signup} className="button gradient-orange">{t("Next")}</a></div>
                        </div>
                  </div>
              </div>
          </div>        
        </Container>
      </div>
      <Footer home={false} register={true}/> 
    </div>

    );
  }
}

export default withTranslation()(Register);
