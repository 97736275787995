import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import { Form, Col, Badge, DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink } from 'reactstrap';
import PropTypes from 'prop-types';
import { AppAsideToggler, AppHeaderDropdown, AppNavbarBrand, AppSidebarToggler } from '@coreui/react';
import Select from 'react-select';
import {Fetch} from '../../services/Fetch';
import config from '../../config';
import { withTranslation } from 'react-i18next';

var player_token;
var player_id;

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

class DefaultHeader extends Component {
  constructor(props) {
    super(props);    
    this.state = {
      redirectToReferrer: false,
      player_clubs:'',
      player_country:'',
      player_name:'',
      player_surname:'',
      player_img:'',
    };
    this.logout = this.logout.bind(this);
    this.getinfo = this.getinfo.bind(this);
    
  }  
  logout(){
     localStorage.setItem("userData",'');
     localStorage.removeItem("userData")
     this.setState({redirectToReferrer: true});
  } 


   async getinfo(token){
      var _json = {}
      var  _res = await Fetch('get','users/getNameByUserToken/'+token,_json);
      this.setState({
          player_clubs:_res.clubs,
          player_country:_res.country,
          player_name:_res.name,
          player_surname:_res.surname,
          player_img:_res.img,    
      })

    }

  componentDidMount() { 
      this.getinfo(player_token);     
   }



  componentWillMount() {

         if(localStorage.getItem("userData")){ 
          var datalogin = JSON.parse(localStorage.getItem("userData"));
          player_id = datalogin.id;          
          player_token = datalogin.token;
         }    
         else{
          this.setState({redirectToReferrer: true});
         }
  }



  render() {
    const { t } = this.props

    if (this.state.redirectToReferrer) {
      return (<Redirect to={'/login'}/>)
    }
 
    const { children, ...attributes } = this.props;
    var {player_clubs,player_country,player_img,player_name,player_surname} = this.state;

    var activeCLassMenu =  function activeRoute(routeName, datas) {
    	return datas.location.pathname.indexOf(routeName) > -1 ? ' active' : '';
  		};	
	{
		
		return(
		<React.Fragment>
            <div className="my-account show">
                <div className="profile-title">{player_name} {player_surname} ({player_country})</div>
                <div className="profile-subtitle">{t("Member in")} {player_clubs} {t("club(s)")}</div>
                <div className="profile-pic"><img src={config.API_URL + 'upload/users/' + player_img} /></div>
                <div className="profile-edit"><a href={config.WEB_URL+'private/profile'}>{t("Edit your profile")}</a></div>
                <div className="profile-logout"><a href={config.WEB_URL+'logout'}>{t("Close session")}</a></div>

            </div>

       <section className="slogan">
        <div className="logo"></div>
        <div className="title">
          <div className="title-1">
          {t("MEET YOUR FRIENDS")}
          </div>
          <div className="title-2">
            &amp;{t("PLAY PRIVATE POKER LIVE")}
          </div>
        </div>
			</section>
			<section className="navigation d-none">
				<nav>
					{attributes.navConfig.items.map((route, idx) => {
						return (<React.Fragment key={idx}><a href={"#"+route.url} className={route.class + activeCLassMenu(route.url, attributes)}><span>{route.name}<span className={route.icon}></span></span></a></React.Fragment>);
					  },
					)}
				</nav>
			</section>
		</React.Fragment>
		);
		
	}
  }
}

DefaultHeader.propTypes = propTypes;
DefaultHeader.defaultProps = defaultProps;

export default withTranslation()(DefaultHeader);
